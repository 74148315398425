import { useEffect, useState } from 'react';
import useAdminTokenCheck from 'src/hooks/useAdminTokenCheck';
import { useParams } from 'react-router-dom';

import AdminWebinarDetailResponse from '../../../../../shared/types/serverResponses/AdminWebinarDetailResponse';
import RegisteredUser from '../../../../../shared/types/RegisteredUser';
import ChatMessageFrontend from '../../../../../shared/types/ChatMessageFrontend';

import adminApi from '../../../lib/adminApi';

import { format } from 'date-fns';

import ReactQuill from 'react-quill';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash } from '@fortawesome/free-solid-svg-icons';

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'image'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
];

const modules = {
    toolbar: {
        container: toolbarOptions,
    },
    imageUploader: {
        upload: async file => {
            const formData = new FormData();
            formData.append("file", file);
            const adminToken = localStorage.getItem('admin-token') as string;
            const url = await adminApi.uploadFile(adminToken, formData);
            return url;
        }
    }
}

document.title = "Detail webináře";
function WebinarDetail() {
    const [webinarData, setWebinarData] = useState<AdminWebinarDetailResponse | null>();
    const [currentChatState, setCurrentChatState] = useState<boolean>(false);
    const adminToken = useAdminTokenCheck();
    const [liveDescription, setLiveDescription] = useState<string>("");

    const { id } = useParams();

    const loadData = async () => {
        const data = await adminApi.getWebinarDetail(adminToken, id as number);
        setLiveDescription(data.webinar.liveDescription ? data.webinar.liveDescription : "");
        setCurrentChatState(!!data.webinar.chatStopped);
        setWebinarData(data);
        document.title = data.webinar.name;
    }

    useEffect(() => {
        loadData();
    }, []);

    const deleteMessage = async (e) => {
        e.preventDefault();
        const { messageid } = e.currentTarget.dataset;
        await adminApi.deleteChatMessage(adminToken, messageid, webinarData?.webinar.id as number);
    }

    const changeChatState = async (e) => {
        await adminApi.changeChatState(adminToken, webinarData?.webinar.id as number, !currentChatState);
        setCurrentChatState(!currentChatState);
    }

    const renderUserRow = (user: RegisteredUser, index: number) => {
        return <tr key={index}>
            <td>{user.firstName}</td>
            <td>{user.lastName}</td>
            <td>{user.company}</td>
            <td>{user.email}</td>
            <td>{user.registeredAt && format(new Date(user.registeredAt), "d. MM. yyyy HH:mm:ss")}</td>
        </tr>;
    }

    const renderChatMessageRow = (message: ChatMessageFrontend, index: number) => {
        return <tr key={index}>
            <td>{message.firstName} {message.lastName}</td>
            <td>{message.message}</td>
            <td>
                <button className="btn btn-danger mr-2" onClick={deleteMessage} data-messageid={message.id}>
                    <FontAwesomeIcon icon={faTrash} />
                </button>
            </td>
        </tr>;
    }

    const updateLiveDescription = async () => {
        if (!webinarData) return;

        await adminApi.updateWebinarLiveDescription(adminToken, webinarData?.webinar.id, liveDescription);
    }

    return <div className="row">
        <h1 className="col-12">{webinarData?.webinar.name}</h1>
        <div className="col-lg-12">
            <h2>Živý popis</h2>
            <ReactQuill
                defaultValue={webinarData?.webinar.liveDescription}
                modules={modules}
                onChange={setLiveDescription}
                value={liveDescription}
            />
            <button className="btn btn-success mt-3" onClick={updateLiveDescription}>Zveřejnit</button>
        </div>
        <div className="col-lg-12 pt-4">
            <hr />
            <h2>Chat:</h2>
            <button onClick={changeChatState} className={`mb-3 btn btn-${currentChatState ? 'success' : 'danger'}`}>
                {currentChatState ? 'Zapnout chat' : 'Vypnout chat'}
            </button>
            <table className="table">
                <thead>
                    <tr>
                        <th>Od</th>
                        <th>Zpráva</th>
                        <th>Akce</th>
                    </tr>
                </thead>
                <tbody>
                    {webinarData?.chatMessages.map((message, index) => renderChatMessageRow(message, index))}
                </tbody>
            </table>
        </div>
    </div>;
}

export default WebinarDetail;