import axios, { AxiosRequestConfig, AxiosResponse } from 'axios';
import AdminLoginResponse from '../../../shared/types/serverResponses/AdminLoginResponse';
import AdminWebinarResponse from '../../../shared/types/serverResponses/AdminWebinarResponse';
import AdminWebinarDetailResponse from '../../../shared/types/serverResponses/AdminWebinarDetailResponse';
import AdminWebinarAddResponse from '../../../shared/types/serverResponses/AdminWebinarAddResponse';
import { loginFormAdmin } from 'src/components/admin/Login';
import { Webinar } from '../../../shared/types/Webinar';
import RegistrationPage from '../../../shared/types/RegistrationPage';
import { AdminRegistrationsResponse } from '../../../shared/types/serverResponses/AdminRegistrationsResponse';
import AdminRegistrationsDetailResponse from '../../../shared/types/serverResponses/AdminRegistrationsDetailResponse';
import { InputType } from '../../../shared/types/InputType';
import { RegistrationType } from '../../../shared/types/RegistrationType';
import { EmailTemplate } from '../../../shared/types/EmailTemplate';
import RegistrationInput from '../../../shared/types/RegistrationInput';

const { REACT_APP_BASE_API_URL } = process.env;

const getAuthorizationHeaders = (token: string): AxiosRequestConfig => {
    return {
        headers: {
            "Authorization": `Bearer ${token}`
        }
    }
}

class AdminApi {
    static async loginAdmin(formValues: loginFormAdmin): Promise<string> {
        const response: AxiosResponse = await axios.post(`${REACT_APP_BASE_API_URL}/admin/login`, formValues);
        const data: AdminLoginResponse = response.data as AdminLoginResponse;
        const { token } = data
        return token;
    }

    static async getWebinars(token: string): Promise<Webinar[]> {
        const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/admin/webinars`, getAuthorizationHeaders(token));
        const data: AdminWebinarResponse = response.data as AdminWebinarResponse;
        return data.data;
    }

    static async getWebinarDetail(token: string, webinarId: number): Promise<AdminWebinarDetailResponse> {
        const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/admin/webinars/detail/${webinarId}`, getAuthorizationHeaders(token));
        const data: AdminWebinarDetailResponse = response.data as AdminWebinarDetailResponse;
        return data;
    }

    static async deleteChatMessage(token: string, messageId: number, webinarId: number): Promise<any> {
        const response: AxiosResponse = await axios.delete(`${REACT_APP_BASE_API_URL}/admin/webinars/${webinarId}/messages/${messageId}`, getAuthorizationHeaders(token));
        const data: AdminWebinarDetailResponse = response.data as any;
        return data;
    }

    static async changeChatState(token: string, webinarId: number, nextState: boolean): Promise<any> {
        const response: AxiosResponse = await axios.put(`${REACT_APP_BASE_API_URL}/admin/webinars/${webinarId}/chat/state/`, { nextState }, getAuthorizationHeaders(token));
        const data: AdminWebinarDetailResponse = response.data as any;
        return data;
    }

    static async updateWebinarLiveDescription(token: string, webinarId: number, description: string): Promise<any> {
        const response: AxiosResponse = await axios.put(`${REACT_APP_BASE_API_URL}/admin/webinars/${webinarId}/liveDescription/`, { description }, getAuthorizationHeaders(token));
        const data: AdminWebinarDetailResponse = response.data as any;
        return data;
    }

    static async addWebinar(token: string, formValues): Promise<AdminWebinarAddResponse> {
        let webinarId: null | number = null;
        let error: null | string = null;
        try {
            const response: AxiosResponse = await axios.post(`${REACT_APP_BASE_API_URL}/admin/webinars`, formValues, getAuthorizationHeaders(token));
            const data: AdminWebinarAddResponse = response.data as any;
            webinarId = data.webinarId;
        }
        catch (e) {
            switch (e.response.status) {
                case 409:
                    error = "Problém při zápisu do databáze. Zřejmě existuje event se stejným URL."
                    break;
                case 400:
                    error = "Špatně vyplněný formulář.";
                    break;
                default:
                    error = "Neznámá chyba";
                    break;
            }
        }
        return { webinarId, error };
    }

    static async editWebinar(token: string, webinarId: number, formValues): Promise<null | string> {
        let error: null | string = null;
        try {
            const response: AxiosResponse = await axios.put(`${REACT_APP_BASE_API_URL}/admin/webinars/${webinarId}`, formValues, getAuthorizationHeaders(token));
        }
        catch (e) {
            switch (e.response.status) {
                case 409:
                    error = "Problém při zápisu do databáze. Zřejmě existuje event se stejným URL."
                    break;
                case 400:
                    error = "Špatně vyplněný formulář.";
                    break;
                default:
                    error = "Neznámá chyba";
                    break;
            }
        }
        return error;
    }

    static async archiveWebinar(token: string, id: number): Promise<null> {
        const response: AxiosResponse = await axios.put(`${REACT_APP_BASE_API_URL}/admin/webinars/archive/${id}`, {}, getAuthorizationHeaders(token));
        return response.data;
    }

    /* Registrations */
    static async getRegistrations(token: string): Promise<RegistrationPage[]> {
        const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/admin/registrations`, getAuthorizationHeaders(token));
        const data: AdminRegistrationsResponse = response.data as AdminRegistrationsResponse;
        return data.data;
    }

    /* Registration report */
    static getRegistrationReport(token: string, id: number): null {
        const element = document.createElement("a");

        // To download the report file
        element.setAttribute("href", `${REACT_APP_BASE_API_URL}/admin/registrations/report/${id}/${token}`);
        element.setAttribute("target", "_BLANK");

        element.click();
        element.remove();

        return null;
    }

    static async getRegistrationsDetail(token: string, registrationId: number): Promise<AdminRegistrationsDetailResponse> {
        const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/admin/registrations/detail/${registrationId}`, getAuthorizationHeaders(token));
        const data: AdminRegistrationsDetailResponse = response.data as AdminRegistrationsDetailResponse;
        return data;
    }

    static async getInputTypes(token: string): Promise<InputType[]> {
        const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/admin/registrations/inputs/types`, getAuthorizationHeaders(token));
        const data: InputType[] = response.data as InputType[];
        return data;
    }

    static async getRegistrationTypes(token: string): Promise<RegistrationType[]> {
        const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/admin/registrations/types`, getAuthorizationHeaders(token));
        const data: RegistrationType[] = response.data as RegistrationType[];
        return data;
    }

    static async archiveRegistration(token: string, id: number): Promise<RegistrationType[]> {
        const response: AxiosResponse = await axios.put(`${REACT_APP_BASE_API_URL}/admin/registrations/archive/${id}`, {}, getAuthorizationHeaders(token));
        return response.data;
    }

    static async addRegistration(token: string, registrationObject: any): Promise<boolean> {
        let isOk = true;

        try {
            const response: AxiosResponse = await axios.post(`${REACT_APP_BASE_API_URL}/admin/registrations/add`, registrationObject, getAuthorizationHeaders(token));
        }
        catch (e) {
            window.alert("Chyba při přidávání registrace!");
            isOk = false;
        }

        return isOk;
    }

    static async editRegistration(token: string, id, registrationObject: any): Promise<boolean> {
        let isOk = true;

        try {
            const response: AxiosResponse = await axios.put(`${REACT_APP_BASE_API_URL}/admin/registrations/edit/${id}`, registrationObject, getAuthorizationHeaders(token));
        }
        catch (e) {
            window.alert("Chyba při úpravě registrace!");
            isOk = false;
        }

        return isOk;
    }

    /* Registration inputs */
    static async addRegistrationInput(token: string, input: RegistrationInput): Promise<any> {
        const response: AxiosResponse = await axios.post(`${REACT_APP_BASE_API_URL}/admin/registrations/${input.registrationId}/inputs`, input, getAuthorizationHeaders(token));
        const data: any = response.data as any;
        return data;
    }

    static async deleteRegistrationInput(token: string, id: number): Promise<any> {
        const response: AxiosResponse = await axios.delete(`${REACT_APP_BASE_API_URL}/admin/registrations/inputs/${id}`, getAuthorizationHeaders(token));
        const data: any = response.data as any;
        return data;
    }

    static async addRegistrationUrl(token: string, registrationId: number, url: string): Promise<any> {
        const response: AxiosResponse = await axios.post(`${REACT_APP_BASE_API_URL}/admin/registrations/${registrationId}/urls`, { url }, getAuthorizationHeaders(token));
        const data: any = response.data as any;
        return data;
    }

    static async deleteRegistrationUrl(token: string, id: number): Promise<any> {
        const response: AxiosResponse = await axios.delete(`${REACT_APP_BASE_API_URL}/admin/registrations/urls/${id}`, getAuthorizationHeaders(token));
        const data = response.data as any;
        return data;
    }

    static async sendEmailToRegistrationUsers(token: string, registrationId: number, emailBody: string, subject: string, shouldSendIcal: boolean = false, sendOnlyToParticipants: boolean = false, sendOnlyToNonParticipants: boolean = false): Promise<string> {
        let result = "";
        try {
            const response: AxiosResponse = await axios.post(`${REACT_APP_BASE_API_URL}/admin/registrations/emailAll/${registrationId}`, { emailBody, subject, shouldSendIcal, sendOnlyToNonParticipants, sendOnlyToParticipants }, getAuthorizationHeaders(token));
            result = JSON.stringify(`Úspěch: ${response.data.successCount}, Error: ${response.data.errCount}`);
        }
        catch (e) {
            switch (e.response.status) {
                case 400:
                    result = "Špatně vyplněný formulář.";
                    break;
                default:
                    result = "Neznámá chyba";
                    break;
            }
        }
        return result;
    }
    static async sendTestEmailForRegistration(token: string, registrationId: number, email: string, emailBody: string, subject: string, shouldSendIcal: boolean = false): Promise<any> {
        const response: AxiosResponse = await axios.post(`${REACT_APP_BASE_API_URL}/admin/registrations/testEmail/${registrationId}`, { emailBody, subject, shouldSendIcal, email }, getAuthorizationHeaders(token));
        const result = response.data;
    }

    static async uploadFile(token: string, formData: FormData) {
        const response: AxiosResponse = await axios.post(`${REACT_APP_BASE_API_URL}/admin/upload`, formData, getAuthorizationHeaders(token));
        return response.data.path;
    }

    static async getEmailTemplates(token: string) {
        const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/admin/email/templates`, getAuthorizationHeaders(token));
        return response.data;
    }

    static async addEmailTemplate(token: string, template: EmailTemplate) {
        const response: AxiosResponse = await axios.post(`${REACT_APP_BASE_API_URL}/admin/email/templates`, template, getAuthorizationHeaders(token));
    }

    static async setRegistrationIsStopped(token: string, registrationId: number, isStopped: boolean): Promise<any> {
        await axios.put(`${REACT_APP_BASE_API_URL}/admin/registrations/${registrationId}/isStopped`, { isStopped }, getAuthorizationHeaders(token));
    }

    /* Delete user from registration */
    static async deleteUser(token: string, registrationId: number, userId: number): Promise<any> {
        await axios.delete(`${REACT_APP_BASE_API_URL}/admin/registrations/${registrationId}/users/${userId}`, getAuthorizationHeaders(token));
    }

    /* Update user in registration */
    static async updateRegistrationUser(token: string, userId: number, userEditObject): Promise<any> {
        await axios.put(`${REACT_APP_BASE_API_URL}/admin/registrations/users/${userId}`, userEditObject, getAuthorizationHeaders(token));
    }

}

export default AdminApi;