//import socket from '../lib/socket';
//import { io, Socket } from 'socket.io-client';
import { format, isBefore, isSameSecond } from 'date-fns';
import { useState, useEffect, useRef } from 'react';

import useSocket from '../hooks/useSocket';

import ChatMessageFrontend from '../../../shared/types/ChatMessageFrontend';
import useAdminTokenCheck from 'src/hooks/useAdminTokenCheck';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faTrash, faReply, faTimes } from '@fortawesome/free-solid-svg-icons'
import adminApi from 'src/lib/adminApi';

import { Socket } from "socket.io-client";

type ChatWindowProps = {
    webinarToken: string,
    webinarId?: number,
    chatStopped: boolean,
    isChatHidden: boolean,
    chatSocket?: Socket,
    hexColor: string,
}

function ChatWindow(props: ChatWindowProps) {
    const { webinarToken, webinarId, chatStopped, isChatHidden, hexColor, chatSocket } = props;
    const [messageCountSinceStop, setMessageCountSinceStop] = useState<number>(0);

    const [message, setMessage] = useState<string>("");
    const [messagesArray, setMessagesArray] = useState<Array<ChatMessageFrontend>>([]);

    const [replyingTo, setReplyingTo] = useState<ChatMessageFrontend | null>(null);;

    const scrollRef = useRef<HTMLDivElement>(null);

    const adminToken = useAdminTokenCheck();

    let socket = useSocket(webinarToken);

    useEffect(() => {
        let realSocket = chatSocket?.connected ? chatSocket : socket;
        if (chatSocket?.connected && socket?.connected) {
            socket?.disconnect();
        }

        realSocket?.on("messagesBefore", (data: ChatMessageFrontend[]) => {
            setMessagesArray(() => [...data]);
            if (scrollRef && scrollRef.current) {
                scrollRef.current.scroll(0, scrollRef.current.scrollHeight);
            }
        });
        realSocket?.on("chatMessage", (data: ChatMessageFrontend) => {
            setMessagesArray((realMessagesArray) => [...realMessagesArray, { ...data }]);
            if (scrollRef && scrollRef.current) {
                scrollRef.current.scroll(0, scrollRef.current.scrollHeight);
            }
            setMessageCountSinceStop((messageCountSinceStop) => messageCountSinceStop + 1);
        });
        realSocket?.on("deleteMessage", (numToDelete: number) => {
            setMessagesArray((realMessagesArray) => {
                return realMessagesArray.filter((message) => message.id !== numToDelete)
            });
        });
        realSocket?.emit("loadChatMessagesBefore", true);

    }, [chatSocket, socket]);

    const handleInputChange = (e: React.FormEvent<HTMLInputElement>): void => {
        setMessage(e.currentTarget.value);
    }

    const formSubmit = (message: string): void => {
        if (!message) return;
        let realSocket = chatSocket ? chatSocket : socket;

        if (replyingTo) {
            realSocket?.emit("reply", { message, parentId: replyingTo.id });
        }
        else {
            realSocket?.emit("chatMessage", message);
        }

        setReplyingTo(null);
        setMessage("");
    }

    const deleteMessage = async (e) => {
        const { messageid } = e.currentTarget.dataset;
        if (webinarId && adminToken) {
            await adminApi.deleteChatMessage(adminToken, messageid, webinarId);
        }
    }

    const renderMessage = (message: ChatMessageFrontend, index: number, replyObject: any): React.ReactElement | null => {
        if (!message) return null;
        if (!message.id) return null;

        /* If previous message has the same parent, this message was already rendered */
        /*if (index > 0 && message.parentId && messagesArray[index - 1].parentId === message.parentId) {
            console.log("skipping render of message ", message);
            return null;
        }*/


        if (!message.parentId && !replyObject[message.id]) {
            const sentAt = format(new Date(message.sentAt), "HH:mm:ss");
            //const replies = messagesArray.filter((possibleReply) => possibleReply.parentId === message.id);

            return <div key={index} className="row pb-3">
                {adminToken && <div className="col-2">
                    <button className="btn btn-danger p-2 mr-3 d-inline-block" onClick={deleteMessage} data-messageid={message.id}>
                        <FontAwesomeIcon style={{ width: "10px" }} icon={faTrash} />
                    </button>
                </div>}
                <div className="d-inline-block col hover-pointer" onClick={() => setReplyingTo(message)}>
                    <span className="text-white d-block chat-message pb-1">
                        {sentAt}
                    </span>
                    <span title={sentAt} className="text-white d-block chat-message pb-1"><span style={{ color: hexColor }}>{`${message.firstName} ${message.lastName}: `}</span>{message.message}</span>
                </div>
            </div>;
        }
        else {
            const parent = message.parentId ? messagesArray.find((parentMessage) => parentMessage.id === message.parentId) : message;

            /* For messages that got their parent deleted */
            if (!parent || !parent.id) {
                return null;
            }

            const sentAtParent = format(new Date(parent.sentAt), "HH:mm:ss");
            const messageSentAt = new Date(message.sentAt);

            if (message.id !== replyObject[parent.id][replyObject[parent.id].length - 1].id) {
                return null;
            }

            //const nextMessage = messagesArray.length > index + 1 ? messagesArray[index + 1] : null;

            //let lastIndexToRenderConversation = 0;
            /*const repliesToRenderBefore = messagesArray.filter((replyBefore, indexOfReplyBefore) => {
                if (replyBefore.parentId !== message.parentId) {
                    return false;
                }
 
                let returnValue: boolean = false;
                const dateOfReply = new Date(replyBefore.sentAt);
                returnValue = (isBefore(dateOfReply, messageSentAt) || isSameSecond(dateOfReply, messageSentAt));
 
 
                /* if (lastIndexToRenderConversation + 1 === index) {
                     returnValue = true;
                 }
 
                 lastIndexToRenderConversation = indexOfReplyBefore;
                return returnValue;
            }
            );*/

            return <div key={index} className="row pb-3">
                {adminToken && <div className="col-2">
                    <button className="btn btn-danger p-2 mr-3 d-inline-block" onClick={deleteMessage} data-messageid={parent.id}>
                        <FontAwesomeIcon style={{ width: "10px" }} icon={faTrash} />
                    </button>
                </div>}
                <div className="d-inline-block col hover-pointer" onClick={() => setReplyingTo(parent)}>
                    <span className="text-white d-block chat-message pb-1">
                        {sentAtParent}
                    </span>
                    <span title={sentAtParent} className="text-white d-block chat-message pb-1"><span style={{ color: hexColor }}>{`${parent.firstName} ${parent.lastName}: `}</span>{parent.message}</span>
                    {replyObject[parent.id].map((reply, index) => {
                        const sentAtReply = format(new Date(reply.sentAt), "HH:mm:ss");
                        return <div key={`${parent.id} - ${index}`}>
                            <span className="text-white d-block chat-message pb-1 ml-3">
                                <span className="text-white d-block chat-message pb-1">
                                    <FontAwesomeIcon icon={faReply} className="d-inline mr-2" />
                                    {sentAtReply}
                                </span>
                                <span style={{ color: hexColor }} title={sentAtReply}>{`${reply.firstName} ${reply.lastName}: `}</span>{reply.message}
                            </span>
                        </div>
                    })}
                </div>
            </div>;
        }

        return null;

    }

    if (isChatHidden) {
        if (messageCountSinceStop !== 0) {
            return <span className="btn btn-danger float-right mr-2 mt-1 px-2" style={{ fontSize: "18px" }}>{messageCountSinceStop}</span>
        }
        return null;
    }

    if (messageCountSinceStop !== 0) {
        setMessageCountSinceStop(0);
    }

    
    let replyObject = {};
    messagesArray.map((message) => {
        if (message.parentId) {
            if (!replyObject[message.parentId]) {
                replyObject[message.parentId] = [];
            }
            replyObject[message.parentId].push(message);
        }
    })

    /*const messagesToRender: ChatMessageFrontend[] = [];

    for (let index = 0; index < messagesArray.length; index++) {
        const message = messagesArray[index];
        const { id } = message;

        if (!id) {
            messagesToRender.push(message);
            continue;
        };

        if (!replyObject[id]) {
            messagesToRender.push(message);
            continue;
        }



        messagesToRender.push(message);

        replyObject[id].map((message) => {  })


    }*/

    return <div className="h-100 bg-black-chat pt-3">
        <div className="chat-replies px-4 pb-4" ref={scrollRef} style={chatStopped ? { maxHeight: "100%" } : { maxHeight: "80%" }}>
            {messagesArray.map((message, index) => {
                return renderMessage(message, index, replyObject);
            })}
        </div>
        {!chatStopped && <div className="chat-input">
            <form>
                {replyingTo && <div>
                    <span className="text-white mb-2 pl-2">Odpovídáte: <span style={{ color: hexColor }}>{replyingTo.firstName} {replyingTo.lastName}</span></span>
                    <FontAwesomeIcon icon={faTimes} className="text-danger float-right mr-2 mt-1 hover-pointer" onClick={() => setReplyingTo(null)}></FontAwesomeIcon>
                </div>}
                <ChatBox hexColor={hexColor} formSubmit={formSubmit} />
            </form>
        </div>}
    </div>;
}

const ChatBox = ({ hexColor, formSubmit }) => {
    const [message, setMessage] = useState("");

    const handleSubmit = (e) => {
        e.preventDefault();
        formSubmit(message);
        setMessage("");
    }

    return <>
        <input type="text" className="form-control chat-textarea" value={message} onChange={(e) => setMessage(e.target.value)} placeholder="Vaše zpráva..." />
        <button className="form-control text-white border-0" style={{ backgroundColor: hexColor }} onClick={handleSubmit}>Odeslat</button>
    </>
}

export default ChatWindow;
export type { ChatWindowProps };