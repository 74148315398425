import useAdminTokenCheck from '../../hooks/useAdminTokenCheck';
import { Redirect, Switch, Route, useRouteMatch, Link } from 'react-router-dom';

// Webinars
import Webinars from './webinars/Webinars';
import WebinarsDetail from './webinars/WebinarDetail';
import WebinarAdd from './webinars/WebinarAdd';
import WebinarEdit from './webinars/WebinarEdit';
// Registrations
import Registrations from './registrations/Registrations';
import RegistrationsDetail from './registrations/RegistrationsDetail';
import RegistrationsAdd from './registrations/RegistrationsAdd';
import RegistrationsEdit from './registrations/RegistrationsEdit';
// Emailing
import Emailing from './emailing/Emailing';
import EmailTemplates from './emailTemplates/EmailTemplates';

const logout = () => {
    localStorage.removeItem("admin-token");
    window.location.reload();
}

function Dashboard() {
    const adminToken = useAdminTokenCheck();
    let { path } = useRouteMatch();

    if (!adminToken) {
        return <Redirect to="/admin/login"></Redirect>;
    }

    return <>
        <nav className="navbar navbar-expand-md navbar-dark fixed-top bg-dark">
            <Link className="navbar-brand" to={path}>Webináře Admin</Link>
            <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse" id="navbarCollapse">
                <ul className="navbar-nav mr-auto">
                    <li className="nav-item">
                        <Link className="nav-link" to={`${path}/webinars`}>Webináře</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`${path}/registrations`}>Registrace</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`${path}/emailing`}>Emailing</Link>
                    </li>
                    <li className="nav-item">
                        <Link className="nav-link" to={`${path}/email/templates`}>Šablony</Link>
                    </li>
                </ul>
                <button className="float-right btn text-white" onClick={logout}>Odhlásit</button>
            </div>
        </nav>
        <div className="padding-under-navbar">
            <Switch>
                {/*Webinars */}
                <Route exact path={`${path}/webinars`}>
                    <Webinars />
                </Route>
                <Route path={`${path}/webinars/add`}>
                    <WebinarAdd />
                </Route>
                <Route path={`${path}/webinars/detail/:id`}>
                    <WebinarsDetail />
                </Route>
                <Route path={`${path}/webinars/edit/:id`}>
                    <WebinarEdit />
                </Route>
                {/* Registrations */}
                <Route exact path={`${path}/registrations`}>
                    <Registrations />
                </Route>
                <Route exact path={`${path}/registrations/add`}>
                    <RegistrationsAdd />
                </Route>
                <Route path={`${path}/registrations/detail/:id`}>
                    <RegistrationsDetail />
                </Route>
                <Route path={`${path}/registrations/edit/:id`}>
                    <RegistrationsEdit />
                </Route>
                {/* Emailing */}
                <Route exact path={`${path}/emailing`}>
                    <Emailing />
                </Route>
                {/* Emailing templates */}
                <Route exact path={`${path}/email/templates`}>
                    <EmailTemplates />
                </Route>
                {/* Default */}
                <Route path={path}>
                    <h1 className="">Nástěnka</h1>
                    <button className="btn btn-success text-white my-5 d-block">
                        <Link className="text-white" to={`${path}/webinars`}>Webináře</Link>
                    </button>
                    <button className="btn btn-warning text-white my-5 d-block">
                        <Link className="text-white" to={`${path}/registrations`}>Registrace</Link>
                    </button>
                    <button className="btn btn-danger text-white my-5 d-block">
                        <Link className="text-white" to={`${path}/emailing`}>Emailing</Link>
                    </button>
                    <button className="btn btn-primary text-white my-5 d-block">
                        <Link className="text-white" to={`${path}/email/templates`}>Šablony</Link>
                    </button>
                </Route>
            </Switch>
        </div>
    </>;
}

export default Dashboard;