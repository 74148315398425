import React, { useEffect, useState } from "react";
import Api from "src/lib/api";
import { Webinar } from "src/shared/types/Webinar";
import StreamWindow from "./StreamWindow";
import { useParams } from 'react-router-dom';
import { Loading } from './loading/Loading';

function WebinarWrapper() {
    const [webinar, setWebinar] = useState<Webinar | null>(null);
    const [token, setToken] = useState<string | null>();

    const { webinarUrl } = useParams();

    const loadWebinarFromUrl = async () => {
        const { webinar, token } = await Api.getWebinarByUserUrl(webinarUrl);
        // temporary
        if (!webinar) {
            window.location.replace("http://agenturaxact.cz");
        }
        // temporary
        setWebinar(webinar);
        setToken(token);
    }

    useEffect(() => {
        loadWebinarFromUrl();
    }, []);

    return <>
        {!webinar && <Loading />}
        {webinar && token && <StreamWindow webinar={webinar} token={token} webinarUrl={webinarUrl}></StreamWindow>}
    </>;

}

export default WebinarWrapper;