import useAdminTokenCheck from 'src/hooks/useAdminTokenCheck';
import RegistrationPage from '../../../../../shared/types/RegistrationPage';
import adminApi from '../../../lib/adminApi';
import { useState, useEffect } from "react";
import { EmailingUser } from '../../../../../shared/types/EmailingUser';
import { parseEmailBody } from '../../../shared/lib/parseEmailBody';

import ReactQuill, { Quill } from 'react-quill';
import ImageUploader from "quill-image-uploader";
import 'react-quill/dist/quill.snow.css';
import { EmailTemplate } from '../../../../../shared/types/EmailTemplate';
Quill.register("modules/imageUploader", ImageUploader);

const sampleUser: EmailingUser = {
    firstName: "Jan",
    lastName: "Novák",
    email: "novak@seznam.cz",
    webinarUrl: "https://webinare.agenturaxact.eu/w/urlUzivatele",
    webinarName: "Testovací webinář",
    webinarDate: "01. 01. 2021",
    webinarTime: "12:00",
    webinarMinutesLength: 60,
    registrationTextBody: "Text v icalu"
}

const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'image'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
];

const modules = {
    toolbar: {
        container: toolbarOptions,
    },
    imageUploader: {
        upload: async file => {
            const formData = new FormData();
            formData.append("file", file);
            const adminToken = localStorage.getItem('admin-token') as string;
            const url = await adminApi.uploadFile(adminToken, formData);
            return url;
        }
    }
}

export default function Emailing() {
    const [emailBody, setEmailBody] = useState<string>("");
    const [subject, setSubject] = useState<string>("");
    const [registrations, setRegistrations] = useState<RegistrationPage[]>([]);
    const [templates, setTemplates] = useState<EmailTemplate[]>([]);
    const [shouldSendIcal, setShouldSendIcal] = useState<boolean>(false);
    const [selectedRegistration, setSelectedRegistration] = useState(0);
    const [apiResult, setApiResult] = useState<string>("");

    const adminToken = useAdminTokenCheck();

    const loadData = async () => {
        const data = await adminApi.getRegistrations(adminToken);
        setRegistrations(data);
        if (data.length) {
            setSelectedRegistration(data[0].id);
        }

        const templateData = await adminApi.getEmailTemplates(adminToken);
        setTemplates(templateData.templates);
    }

    useEffect(() => {
        loadData();
    }, []);

    const emailBodyChange = (body) => {
        setEmailBody(body);
    }

    const renderRegistrationOption = (registration: RegistrationPage, index: number) => {
        return <option key={index} value={registration.id}>{registration.id} - {registration.registrationName}</option>
    }

    const renderTemplateOption = (template: EmailTemplate, index: number) => {
        return <option key={index} value={index}>{template.id} - {template.name}</option>
    }

    const selectedRegistrationChange = (e) => {
        setSelectedRegistration(parseInt(e.target.value));
    }

    const sendEmail = async (sendOnlyToParticipants = false, sendOnlyToNonParticipants = false) => {
        let text = "Opravdu chcete odeslat tento email VŠEM registrovaným?";
        if (sendOnlyToParticipants) text = "Opravdu chcete odeslat tento email na lidi, co PŘIŠLI?"
        if (sendOnlyToNonParticipants) text = "Opravdu chcete odeslat tento email na lidi, co NEPŘIŠLI?";

        if (window.confirm(text)) {
            const result = await adminApi.sendEmailToRegistrationUsers(adminToken, selectedRegistration, emailBody, subject, shouldSendIcal, sendOnlyToParticipants, sendOnlyToNonParticipants);
            window.alert(result);
        }
    }

    const selectTemplate = (e) => {
        const index = parseInt(e.target.value);

        if (!Number.isInteger(index) || index < 0) {
            return;
        }

        const selectedTemplate = templates[index];

        setSubject(selectedTemplate.subject);
        setEmailBody(selectedTemplate.body);
        setShouldSendIcal(selectedTemplate.shouldSendIcal);
    }

    const subjectChange = (e) => {
        setSubject(e.target.value);
    }

    const shouldSendIcalChange = (e) => {
        setShouldSendIcal(e.target.checked);
    }

    const saveTemplate = async () => {
        const templateName = prompt("Zadejte název šablony");
        if (!templateName) {
            return;
        }
        try {
            await adminApi.addEmailTemplate(adminToken, { name: templateName, body: emailBody, subject, shouldSendIcal });
            alert("Šablona přidána");
        }
        catch (e) {
            alert("Chyba při přidávání šablony!");
        }
    }

    const sendTestEmail = async () => {
        const email = window.prompt("Zadejte email na který má být zaslán testovací email.");

        if (!email) return;

        try {
            const result = await adminApi.sendTestEmailForRegistration(adminToken, selectedRegistration, email, emailBody, subject, shouldSendIcal);
            window.alert("Odesláno!");
        }
        catch(e) {
            window.alert("Nepodařilo se odeslat testovací email. Buď je registrace prázdná, nebo byl nesprávně vyplněn formulář.");
            console.log(e);
        }
    }

    return <div className="row">
        <div className="col-lg-8">
            <label>Pro registraci:</label>
            <select className="form-control" onChange={selectedRegistrationChange}>
                {registrations.map((registration, index) => renderRegistrationOption(registration, index))}
            </select>

            <label className="mt-3">Ze šablony:</label>
            <select className="form-control" onChange={selectTemplate} defaultValue={-1}>
                <option disabled value="-1">Vyberte šablonu</option>
                {templates.map((template, index) => renderTemplateOption(template, index))}
            </select>

            <label className="mt-3">Předmět:</label>
            <input className="form-control" placeholder="Předmět" name="subject" onChange={subjectChange} value={subject}></input>

            <label className="mt-3">Text emailu:</label>
            <ReactQuill
                defaultValue={emailBody}
                modules={modules}
                onChange={emailBodyChange}
                value={emailBody}
            />

            <div className="form-check my-4">
                <input type="checkbox" className="form-check-input" onClick={shouldSendIcalChange} checked={shouldSendIcal} />
                <label className="form-check-label">Odeslat i invite.ics do kalendáře společně s emailem?</label>
            </div>

            <button className="btn btn-success" onClick={() => sendEmail(false, false)}>Odeslat email VŠEM</button>
            <button className="btn bg-blue-calm text-white mx-3" onClick={saveTemplate}>Uložit jako šablonu</button>
            <button className="btn btn-warning ml-3" onClick={() => sendEmail(true, false)}>Odeslat email PŘIŠLI</button>
            <button className="btn btn-danger ml-3" onClick={() => sendEmail(false, true)}>Odeslat email NEPŘIŠLI</button>
            <button className="btn btn-secondary ml-3" onClick={sendTestEmail}>Odeslat TESTOVACÍ email</button>
        </div>
        <div className="col-lg-4">
            <label>Proměnné:</label>
            <p><b>{"{{firstName}}"}</b> - jméno</p>
            <p><b>{"{{lastName}}"}</b> - příjmení</p>
            <p><b>{"{{email}}"}</b> - email</p>
            <p><b>{"{{webinarUrl}}"}</b> - celý odkaz na webinář pro danného uživatele</p>
            <p><b>{"{{webinarName}}"}</b> - název webináře</p>
            <p><b>{"{{webinarDate}}"}</b> - datum webináře</p>
            <p><b>{"{{webinarTime}}"}</b> - čas webináře</p>
        </div>
    </div>;
}