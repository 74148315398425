import { useState } from 'react';
import useAdminTokenCheck from 'src/hooks/useAdminTokenCheck';
import adminApi from 'src/lib/adminApi';
import { useHistory } from 'react-router-dom';

const templates = {
    CRA: {
        name: "Webinář CRA",
        videoUrl: "",
        startDate: "",
        startTime: "00:00",
        minutesLength: 60,
        partnerGraphicsBannerUrl: "",
        backgroundUrl: "https://webinare.jakub.dev/uploads/gray.png",
        hexColor: "#f59c04"
    },
    DELL: {
        name: "Webinář DELL",
        videoUrl: "",
        startDate: "",
        startTime: "00:00",
        minutesLength: 60,
        partnerGraphicsBannerUrl: "https://webinare.jakub.dev/uploads/dt.png",
        backgroundUrl: "https://webinare.jakub.dev/uploads/pozadi-hexa1.jpg",
        hexColor: "#3074bc"
    },
    SWS: {
        name: "Webinář SWS",
        videoUrl: "",
        startDate: "",
        startTime: "00:00",
        minutesLength: 60,
        partnerGraphicsBannerUrl: "https://webinare.jakub.dev/uploads/sws.png",
        backgroundUrl: "https://webinare.jakub.dev/uploads/sws-bg.png",
        hexColor: "#ffbe44"
    },
    STOMEGA: {
        name: "Webinář 100MEGA",
        videoUrl: "",
        startDate: "",
        startTime: "00:00",
        minutesLength: 60,
        partnerGraphicsBannerUrl: "https://webinare.jakub.dev/uploads/logo-100mega.png",
        backgroundUrl: "https://webinare.jakub.dev/uploads/100mega_bg2.png",
        hexColor: "#e82300"
    }
}

export default function WebinarAdd() {
    document.title = "Přidat webinář";

    const [formValues, setFormValues] = useState(templates.DELL);

    const [selectedFile, setSelectedFile] = useState("");
    const [selectedBackground, setSelectedBackground] = useState("");
    const [error, setError] = useState<null | string>(null);

    const adminToken = useAdminTokenCheck();
    const history = useHistory();

    const webinarInputChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }

    const fileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    }

    const backgroundFileChange = (e) => {
        setSelectedBackground(e.target.files[0]);
    }

    const formSubmit = async (e) => {

        let formValuesToSubmit = formValues;

        if (selectedFile) {
            const formData = new FormData();
            formData.append("file", selectedFile);
            let url = await adminApi.uploadFile(adminToken, formData);
            formValuesToSubmit["partnerGraphicsBannerUrl"] = url;
        }

        if (selectedBackground) {
            const formData = new FormData();
            formData.append("file", selectedBackground);
            let url = await adminApi.uploadFile(adminToken, formData);
            formValuesToSubmit["backgroundUrl"] = url;
        }

        const result = await adminApi.addWebinar(adminToken, formValuesToSubmit);
        const { webinarId, error } = result;

        setError(error);

        if (webinarId) {
            history.push(`/admin/webinars/detail/${webinarId}`);
        }
    }

    const setTemplate = (template) => {
        setFormValues(templates[template]);
    }

    return <div className="pb-5">
        <h1>Přidat webinář</h1>

        <label className="w-100">Šablona:</label>
        <button className="btn btn-primary" onClick={() => setTemplate("DELL")}>DELL</button>
        <button className="btn btn-warning text-white ml-3" onClick={() => setTemplate("CRA")}>CRA</button>
        <button className="btn btn-success text-white ml-3" onClick={() => setTemplate("SWS")}>SWS</button>
        <button className="btn btn-danger text-white ml-3" onClick={() => setTemplate("STOMEGA")}>100MEGA</button>

        <p>{error}</p>
        <label>Název webináře*</label>
        <input className="form-control mb-3" type="text" name="name" value={formValues.name} onChange={webinarInputChange}></input>

        <label>Datum webináře*</label>
        <input className="form-control mb-3" type="date" name="startDate" onChange={webinarInputChange}></input>

        <label>Start webináře*</label>
        <input className="form-control mb-3" type="time" name="startTime" onChange={webinarInputChange}></input>

        <label>Délka webináře v minutách*</label>
        <input className="form-control mb-3" type="number" name="minutesLength" defaultValue="60" onChange={webinarInputChange}></input>

        <label>Loga vedle webináře</label>
        <input className="form-control mb-3" type="file" name="file" onChange={fileChange}></input>

        <label>Pozadí - <a href={formValues.backgroundUrl} target="BLANK">Výchozí</a></label>
        <input className="form-control mb-3" type="file" name="file" onChange={backgroundFileChange}></input>

        <label>Odkaz na video - pouze poslední část URL, například https://www.youtube.com/watch?v=<strong>dQw4w9WgXcQ</strong> nebo https://youtu.be/<strong>dQw4w9WgXcQ</strong></label>
        <input className="form-control mb-3" type="text" name="videoUrl" value={formValues.videoUrl} onChange={webinarInputChange}></input>

        <label>Barva webináře - Výchozí: {formValues.hexColor}</label>
        <input className="form-control form-control-color" type="color" id="hexColor" name="hexColor" onChange={webinarInputChange} value={formValues.hexColor}></input>

        <br />

        <button className="btn btn-success" onClick={formSubmit}>Přidat webinář</button>
    </div>
}