import { useState } from 'react';
import adminApi from '../../lib/adminApi';

export type loginFormAdmin = {
    username: string,
    password: string
}

export default function Login() {

    const [formValues, setFormValues] = useState<loginFormAdmin>({
        username: "",
        password: ""
    });

    const [error, setError] = useState("");

    const onInputChange = (e: React.FormEvent<HTMLInputElement>) => {
        setFormValues({ ...formValues, [e.currentTarget.name]: e.currentTarget.value });
    }

    const onFormSubmit = async (e) => {
        e.preventDefault();
        try {
            const token: string = await adminApi.loginAdmin(formValues);
            if (token) {
                localStorage.setItem("admin-token", token);
            }
            window.location.href = "/admin";
        }
        catch (e) {
            setError("Nesprávné přihlašovací údaje!");
        }
    }

    return <div className="container p-5">
        <h1>Přihlášení do administrace</h1>
        <form>
            <input className="form-control my-2" name="username" value={formValues.username} onChange={onInputChange} placeholder="Username" type="text"></input>
            <input className="form-control my-2" name="password" value={formValues.password} onChange={onInputChange} placeholder="Password" type="password"></input>
            <button className="btn btn-secondary" onClick={onFormSubmit}>Login</button>
            <p className="text-danger">{error}</p>
        </form>
    </div>
}
