/* So the whole page doesnt have to reload */
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faPen, faSave } from '@fortawesome/free-solid-svg-icons';

import { addHours, format } from 'date-fns';
import { Link } from 'react-router-dom';

import RegistrationInput from 'src/shared/types/RegistrationInput';

export const RegistrationNormalRow = (props) => {
    const { index, deleteUser, toggleUserEdit, user, registrationTypes, foundUrl, registrationsDetail } = props;
    return <tr key={`${index} - ${user.id} - normal`}>
        {/* Action */}
        <td>
            <button className="btn btn-danger mx-1" onClick={() => deleteUser(user)}>
                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </button>
            <button className="btn btn-warning text-white mx-1" onClick={() => toggleUserEdit(user)}>
                <FontAwesomeIcon icon={faPen}></FontAwesomeIcon>
            </button>
        </td>
        {/* Data */}
        <td>{user.firstName}</td>
        <td>{user.lastName}</td>
        <td>{user.email}</td>
        <td>{user.company}</td>
        <td>{registrationTypes[user.registrationType]}</td>
        <td>{format(addHours(new Date(user.registeredAt), 1), "dd. MM. yyyy HH:mm:ss")}</td>
        <td>{user.originUrlId} - <Link target="_blank" to={`/r/${foundUrl?.url}`}>{foundUrl?.url}</Link></td>
        <td>{registrationsDetail?.participatingUsersIds.includes(user.id) ? "Ano" : "Ne"}</td>
        <td>
            {user.userUrl && <>
                <Link target="_blank" to={`/chat/${user.userUrl}`} className="">Chat</Link>
                <span> / </span>
                <Link target="_blank" to={`/w/${user.userUrl}`}>Webinář</Link>
            </>}
            {!user.userUrl && <span>Chyba!</span>}
        </td>
        {!!registrationsDetail?.registrationPage.isPaid && <td>{user.proformaId}</td>}
        {!!registrationsDetail?.registrationPage.isPaid && <td>{user.realInvoiceId}</td>}
        {registrationsDetail?.inputKeys.map((key: string, index: number) => {
            return <td key={`${index} - ${user.id}`}>{registrationsDetail.mappedUserInputs[user.id]["inputs"][key]}</td>
        })}
    </tr>
}

export const RegistrationEditRow = (props) => {
    const { index, deleteUser, toggleUserEdit, user, registrationTypes, foundUrl, registrationsDetail, onUserValueChange, userEditObject, onUserInputValueChange } = props;
    return <tr key={`${index} - ${user.id} - edit`}>
        {/* Action */}
        <td>
            <button className="btn btn-danger mx-1" onClick={() => deleteUser(user)}>
                <FontAwesomeIcon icon={faTrash}></FontAwesomeIcon>
            </button>
            <button className="btn btn-success text-white mx-1" onClick={() => toggleUserEdit(user)}>
                <FontAwesomeIcon icon={faSave}></FontAwesomeIcon>
            </button>
        </td>
        {/* Data */}
        <td>
            <input className="form-control" type="text" placeholder="Jméno" name="firstName" data-userid={user.id} onChange={onUserValueChange} value={userEditObject[user.id]["firstName"]}>
            </input>
        </td>
        <td>
            <input className="form-control" type="text" placeholder="Příjmení" name="lastName" data-userid={user.id} onChange={onUserValueChange} value={userEditObject[user.id]["lastName"]}>
            </input>
        </td>
        <td>
            <input className="form-control" type="text" placeholder="Email" name="email" data-userid={user.id} onChange={onUserValueChange} value={userEditObject[user.id]["email"]}>
            </input>
        </td>
        <td>
            <input className="form-control" type="text" placeholder="Společnost" name="company" data-userid={user.id} onChange={onUserValueChange} value={userEditObject[user.id]["company"]}>
            </input>
        </td>
        <td>{registrationTypes[user.registrationType]}</td>
        <td>{format(addHours(new Date(user.registeredAt), 1), "dd. MM. yyyy HH:mm:ss")}</td>
        <td>{user.originUrlId} - <Link target="_blank" to={`/r/${foundUrl?.url}`}>{foundUrl?.url}</Link></td>
        <td>{registrationsDetail?.participatingUsersIds.includes(user.id) ? "Ano" : "Ne"}</td>
        <td>
            {user.userUrl && <>
                <Link target="_blank" to={`/chat/${user.userUrl}`} className="">Chat</Link>
                <span> / </span>
                <Link target="_blank" to={`/w/${user.userUrl}`}>Webinář</Link>
            </>}
            {!user.userUrl && <span>Chyba!</span>}
        </td>
        {!!registrationsDetail?.registrationPage.isPaid && <td>{user.proformaId}</td>}
        {!!registrationsDetail?.registrationPage.isPaid && <td>{user.realInvoiceId}</td>}
        {registrationsDetail?.inputKeys.map((key: string, index: number) => {
            const editedInput = userEditObject[user.id].inputs.find((input: RegistrationInput) => input.inputName === key);
            if (!editedInput) return;
            return <td key={`${index} - ${key}`}>
                <input className="form-control" type="text" placeholder={key} name={key} data-userid={user.id} onChange={onUserInputValueChange} value={editedInput.inputValue}>
                </input>
            </td>
        })}

    </tr>

}