import React from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
} from "react-router-dom";
import './App.css';
import AdminPanel from './components/admin/AdminPanel';
import ChatWrapper from './components/ChatWrapper';
import WebinarWrapper from './components/WebinarWrapper';
import RegistrationWrapper from './components/registration/RegistrationWrapper';
import PanelWrapper from './components/panels/PanelWrapper';
import CustomRegistrationWrapper from './components/registration/100MegaCustomRegistration/CustomRegistrationWrapper';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/w/:webinarUrl">
          <WebinarWrapper></WebinarWrapper>
        </Route>
        <Route exact path="/chat/:webinarUrl">
          <ChatWrapper></ChatWrapper>
        </Route>
        <Route path="/admin">
          <AdminPanel></AdminPanel>
        </Route>
        <Route path="/r/BAHNA_2022">
          <CustomRegistrationWrapper></CustomRegistrationWrapper>
        </Route>
        <Route path="/r/:url">
          <RegistrationWrapper></RegistrationWrapper>
        </Route>
        <Route path="/panels/:userUrl">
          <PanelWrapper></PanelWrapper>
        </Route>
        {/*<Route path="/">
          <h1>Domovská stránka</h1>
        </Route>*/}
      </Switch>
    </Router>
  );
}

export default App;
