import YouTube, { Options } from 'react-youtube';
import { Webinar } from '../shared/types/Webinar';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faExternalLinkAlt, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';

import ChatWindow from './ChatWindow';
import { useEffect, useState } from 'react';
import useSocket from 'src/hooks/useSocket';

type StreamWindowProps = {
    webinar: Webinar;
    token: string;
    webinarUrl: string;
};

const playerOptions: Options = {
    height: '390',
    width: '640',
    playerVars: {
        autoplay: 1,
        rel: 0,
    },
}

function StreamWindow(props: StreamWindowProps) {
    const { webinar, token, webinarUrl } = props;

    const [isChatHidden, setIsChatHidden] = useState<boolean>(false);
    const [liveDescription, setLiveDescription] = useState<string>("");

    const socket = useSocket(token);

    useEffect(() => {
        socket?.on("updateLiveDescription", (description) => {
            setLiveDescription(description);
        });
        socket?.emit("loadWebinarLiveDescription");
    }, [socket])

    document.title = webinar.name;

    const { REACT_APP_BASE_API_URL } = process.env;

    return <div className="row vh-xl-100 no-gutters" style={{ backgroundImage: `url(${webinar.backgroundUrl})`, backgroundRepeat: "no-repeat", backgroundSize: "cover" }}>
        <div className="row col-12 no-gutters">
            <div className="col-12 row no-gutters">
                <div className="col-lg-2">
                </div>
                <div className={`col-lg-9 col-xl-${isChatHidden ? 9 : 8}`}>
                    <h1 className="text-white webinar-name w-100">{webinar.name}</h1>
                </div>
                <div className={`col-lg-3 col-xl-${isChatHidden ? 1 : 2} pt-2`} style={{ minHeight: "6vh" }}>
                    {!isChatHidden && <h2 className="text-white d-inline float-left chat-heading mt-2">Chat:</h2>}
                    <div className="mb-2 d-inline float-right pr-2">
                        <button className="btn btn-warning mt-1 px-2 py-1 text-white" onClick={() => { setIsChatHidden(!isChatHidden) }}>
                            <FontAwesomeIcon style={{ width: "12px" }} className="" icon={isChatHidden ? faEye : faEyeSlash} />
                        </button>
                        <Link className="btn btn-success mt-1 px-2 py-1 ml-3" to={`/chat/${webinarUrl}`} target="_blank">
                            <FontAwesomeIcon style={{ width: "12px" }} className="" icon={faExternalLinkAlt} />
                        </Link>
                    </div>
                </div>
            </div>
            <div className="col-lg-2 d-lg-block d-none px-4">
                <img alt={webinar.partnerGraphicsBannerUrl ? "Webinar partner logo" : ""} src={webinar.partnerGraphicsBannerUrl} className="partners-logo float-left" />
            </div>
            <div className={`col-lg-${isChatHidden ? 9 : 8} vh-xl-100 px-3 text-white`}>
                {webinar.videoUrl && <YouTube videoId={webinar.videoUrl} opts={playerOptions} className="player-full-width webinar-window"></YouTube>}
                <div dangerouslySetInnerHTML={{ __html: liveDescription }}></div>
            </div>
            <div className={`col-lg-${isChatHidden ? 1 : 2} chat-sizing`}>
                {token && socket && <ChatWindow chatSocket={socket} chatStopped={!!webinar.chatStopped} isChatHidden={isChatHidden} webinarToken={token} webinarId={webinar.id} hexColor={webinar.hexColor}></ChatWindow>}
            </div>
        </div>
    </div>
}

export default StreamWindow;
export type { StreamWindowProps };