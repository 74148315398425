import axios, { AxiosResponse } from 'axios';
import { plainToClass } from 'class-transformer';
import RegistrationPage from 'src/shared/types/RegistrationPage';
import RegisteredUser from '../../../shared/types/RegisteredUser';
import RegistrationInput from '../../../shared/types/RegistrationInput';
import { Webinar } from '../../../shared/types/Webinar';
import { RegisterForm } from '../shared/types/RegisterForm';
import ServerResponse from '../shared/types/ServerResponse';

const { REACT_APP_BASE_API_URL } = process.env;

class Api {
    static async registerUser(registerForm: RegisterForm): Promise<RegisteredUser> {
        const response: ServerResponse<RegisteredUser> = await axios.post(`${REACT_APP_BASE_API_URL}/user`, registerForm);
        const serverRes: ServerResponse<RegisteredUser> = plainToClass(ServerResponse, response.data);
        return serverRes.data!;
    }

    static async getWebinarByUrl(webinarUrl: string): Promise<Webinar> {
        const response: ServerResponse<Webinar> = await axios.get(`${REACT_APP_BASE_API_URL}/webinar/${webinarUrl}`);
        const serverRes: ServerResponse<Webinar> = plainToClass(ServerResponse, response.data);
        return serverRes.data!;
    }

    static async getWebinarVideo(token: string, webinarId: number): Promise<string> {
        const response: ServerResponse<string> = await axios.get(`${REACT_APP_BASE_API_URL}/webinar/video/${webinarId}`, {
            headers: {
                "Authorization": `Bearer ${token}`
            }
        });
        const serverRes: ServerResponse<string> = plainToClass(ServerResponse, response.data);
        return serverRes.data!;
    }

    static async getWebinarByUserUrl(webinarUrl: string): Promise<any> {
        const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/webinar/user/${webinarUrl}`);
        const data = response.data as any;
        const webinar: Webinar = data.webinar;
        const token: string = data.token;
        return { webinar, token };
    }

    /* Registration */
    static async getRegistrationByUrl(registrationUrl: string): Promise<RegistrationPage> {
        const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/registration/${registrationUrl}`);
        const data: RegistrationPage = response.data as any;
        return data;

    }

    static async getRegistrationInputs(registrationId: number, registrationType: number): Promise<any> {
        let error: null | string = null;
        let inputs: RegistrationInput[] = [];
        try {
            const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/registration/inputs/${registrationId}/${registrationType}`);
            inputs = response.data as any;
        }
        catch (e) {
            switch (e.response.status) {
                case 455:
                    error = registrationId !== 146 ? "Omlouváme se, ale kapacita této kategorie již byla naplněna. Obraťte se prosím na svého obchodního zástupce." : "Omlouváme se, ale kapacita této kategorie již byla naplněna. V případě zájmu o registraci, kontaktujte nás dns@dns.cz";
                    break;
                case 456:
                    error = registrationId !== 146 ? "Omlouváme se, ale kapacita již byla naplněna. Obraťte se prosím na svého obchodního zástupce." : "Omlouváme se, ale kapacita již byla naplněna. V případě zájmu o registraci, kontaktujte nás dns@dns.cz";
                    break;
                default:
                    error = "Neznámá chyba";
                    break;
            }
        }
        return { error, inputs };

    }

    static async submitRegistrationInputs(registrationId: number, registrationType: number, inputs: any) {
        let error: null | string = null;
        let isOk: boolean = true;
        try {
            const response: AxiosResponse = await axios.post(`${REACT_APP_BASE_API_URL}/registration/inputs/${registrationId}/${registrationType}`, inputs);
        }
        catch (e) {
            isOk = false;
            switch (e.response.status) {
                case 409:
                    error = "Tento email byl již pro registraci použit."
                    break;
                case 400:
                    error = "Špatně vyplněný formulář nebo formulář obsahuje nepovolené znaky.";
                    break;
                case 455:
                    error = "Omlouváme se, ale kapacita této kategorie již byla naplněna. Obraťte se prosím na svého obchodního zástupce.";
                    break;
                case 456:
                    error = "Omlouváme se, ale kapacita již byla naplněna. Obraťte se prosím na svého obchodního zástupce.";
                    break;
                case 457:
                    error = "Omlouváme se, ale kapacita vybrané možnosti byla již naplněna. Vyplňte formulář znovu.";
                    break;
                default:
                    error = "Neznámá chyba";
                    break;
            }
        }
        return { error, isOk };
    }

    static async getSaleReferents(): Promise<string[]> {
        const response: AxiosResponse = await axios.get(`${REACT_APP_BASE_API_URL}/saleReferents`);
        return response.data;
    }
}

export default Api;