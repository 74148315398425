import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCircleNotch } from '@fortawesome/free-solid-svg-icons'
import { useState, useEffect } from 'react';

export const Loading = () => {

    const [message, setMessage] = useState("");

    useEffect(() => {
        setTimeout(() => {
            setMessage("Server je zaneprázdněn, nebo je odkaz neplatný...");
        }, 5000);
    }, [])

    return <div className="w-100" style={{ backgroundImage: "url(https://webinare.jakub.dev/uploads/gray.png)", height: "100vh" }}>
        <div className="w-100" style={{ position: "absolute", top: "40%" }}>
            <div className="w-100 text-center">
                <h1 className="text-white">Načítání...</h1>
                <FontAwesomeIcon icon={faCircleNotch} size="6x" spin={true} className="text-white"></FontAwesomeIcon>
                <h2 className="text-white">{message}</h2>
            </div>
        </div>
    </div>
}