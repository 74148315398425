class ServerResponse<T> {
    constructor(data?: T, statusCode?: number, error?: string) {
        this.data = data;
        this.statusCode = statusCode;
        this.error = error;
    }
    data?: T;
    statusCode?: number;
    error?: string;
}

export type { ServerResponse };
export default ServerResponse;