import { useState, useEffect } from 'react';
import RegistrationInput from '../../../../shared/types/RegistrationInput';
import api from '../../lib/api';
import { countries } from '../../shared/enums/countries';

type RegistrationFormProps = {
    registrationEmail: string;
    firstName: string;
    lastName: string;
    company: string;
    registrationId: number;
    registrationType: number;
    registrationUrl: string;
    updateStep: () => void;
    step: number;
    setStep: (number: number) => void;
    resetForm: () => void;
    dataProtectionHtml: string;
    hexColor: string;
    isPaid: boolean;
    priceCZK: null | number;
}

export default function RegistrationForm(props: RegistrationFormProps) {
    const { registrationId, registrationType, registrationEmail, updateStep, firstName, lastName, company, registrationUrl, step, resetForm, dataProtectionHtml, hexColor, setStep, isPaid, priceCZK } = props;
    const [formInputs, setFormInputs] = useState<RegistrationInput[]>([]);
    const [formValues, setFormValues] = useState<any>({});
    const [errorMessage, setErrorMessage] = useState<string | null>(null);

    const [saleReferents, setSaleReferents] = useState<string[]>([]);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const loadInputs = async () => {
        setIsLoading(true);

        const { inputs, error } = await api.getRegistrationInputs(registrationId, registrationType);

        if (error) {
            setIsLoading(false);
            setErrorMessage(error);
            return;
        }

        setFormInputs(inputs);

        let shouldLoadSaleReferents = false;
        for (const input of inputs) {
            if (input.registrationInputTypeId === 4) {
                shouldLoadSaleReferents = true;
            }
        }

        if (shouldLoadSaleReferents) {
            const referentsArray = await api.getSaleReferents();
            setSaleReferents(referentsArray);
        }

        setIsLoading(false);
    }

    useEffect(() => {
        loadInputs();
    }, []);

    const formSubmit = async (e) => {
        e.preventDefault();

        const arrayOfInputValues = Object.keys(formValues).map((key) => {
            return { inputId: parseInt(key), value: formValues[key] };
        })

        if (formInputs.length !== Object.keys(formValues).length) {
            formInputs.forEach((input) => {
                if (!input.id) return;

                if (!formValues[input.id?.toString()]) {
                    console.log("Input not found, adding with null value.", input);
                    arrayOfInputValues.push({ inputId: input.id, value: " " });
                }
            })
        }

        const { error, isOk } = await api.submitRegistrationInputs(registrationId, registrationType, {
            inputs: arrayOfInputValues,
            email: registrationEmail,
            company: company,
            firstName: firstName,
            lastName: lastName,
            registrationUrl: registrationUrl
        });

        if (isOk) {
            updateStep();

            // Giga hack for registrations 88 and 87
            /*
            if (registrationId === 88 && (formValues[144] === "Ano" || formValues[145] === "Ano" || formValues[146] === "Ano")) {

                const mappingObject = {
                    143: 138,
                    142: 137,
                    146: 139,
                    145: 140,
                    144: 141,
                }

                const newArrayOfInputValues = Object.keys(formValues).map((key) => {
                    return { inputId: mappingObject[parseInt(key)], value: formValues[key] };
                })

                const { error, isOk } = await api.submitRegistrationInputs(87, registrationType, {
                    inputs: newArrayOfInputValues,
                    email: registrationEmail,
                    company: company,
                    firstName: firstName,
                    lastName: lastName,
                    registrationUrl: registrationUrl
                });
            }

            if (registrationId === 87 && (formValues[141] === "Ano" || formValues[140] === "Ano" || formValues[139] === "Ano")) {

                const mappingObject = {
                    138: 143,
                    137: 142,
                    139: 146,
                    140: 145,
                    141: 144,
                }

                const newArrayOfInputValues = Object.keys(formValues).map((key) => {
                    return { inputId: mappingObject[parseInt(key)], value: formValues[key] };
                })

                const { error, isOk } = await api.submitRegistrationInputs(88, registrationType, {
                    inputs: newArrayOfInputValues,
                    email: registrationEmail,
                    company: company,
                    firstName: firstName,
                    lastName: lastName,
                    registrationUrl: registrationUrl
                });
            }
            */
        }

        document.getElementById("registration-success")?.scrollIntoView();

        if (error) {
            setErrorMessage(error);
        }
    }

    const onInputChange = (e) => {
        //console.log(e.target.value);
        setFormValues({ ...formValues, [e.target.name]: e.target.value })
    }

    const renderInput = (input: RegistrationInput, index: number, step: number) => {
        if (!input.id) return;

        if (step === 2 && input.prerequisiteInputId) {
            return null;
        }

        if (input.prerequisiteInputId) {

            const prerequisiteInput = formInputs.find((allInputs) => input.prerequisiteInputId === allInputs.id);

            if (!prerequisiteInput?.id) {
                console.log("Did not find input...");
                return null;
            }

            // The radio Ano/Ne is false -> do not show inputs and show only GDPR
            // If this is the case, fill all the inputs with "Ne" value and submit

            if (formValues[prerequisiteInput.id?.toString()] === "Ne") {
                formValues[input.id.toString()] = "Ne";
                return null;
            }
        }

        /* Country select */
        if (input.registrationInputTypeId === 8) {
            const currentValue = formValues[input.id.toString()];

            if (!currentValue) {
                setFormValues({ ...formValues, [input.id.toString()]: countries[0] });
            }

            return <div key={index}>
                <label>{input.inputLabel}</label>
                <select className="form-control mb-2" placeholder={input.inputLabel} name={input.id.toString()} onChange={onInputChange} defaultValue={countries[0]} disabled={step === 4}>
                    {countries.map((country: string) => {
                        return <option key={country} value={country}>{country}</option>;
                    })}
                </select>
            </div>
        }
        /* Ano/Ne */
        else if (input.registrationInputTypeId === 6) {
            const currentValue = formValues[input.id.toString()];

            if (!currentValue) {
                const defaultValue = input.isDisabled ? "Ne" : "Ano";
                setFormValues({ ...formValues, [input.id.toString()]: defaultValue });
            }

            return <div className="pt-2 pb-4" key={index}>
                <label dangerouslySetInnerHTML={{ __html: input.inputLabel }} className="text-bold"></label>
                <div className="form-check">
                    <input type="radio" required className="form-check-input" name={input.id.toString()} placeholder={input.inputLabel} onChange={onInputChange} value="Ano" disabled={step > 2 || input.isDisabled}></input>
                    <label className="form-check-label">Ano</label>
                </div>
                <div className="form-check">
                    <input type="radio" required className="form-check-input" name={input.id.toString()} placeholder={input.inputLabel} onChange={onInputChange} value="Ne" disabled={step > 2 || input.isDisabled} checked={!!input.isDisabled ? !!input.isDisabled : formValues[input.id.toString()] == "Ne"}></input>
                    <label className="form-check-label">Ne</label>
                </div>
            </div>;

        }
        /* Checkbox */
        else if (input.registrationInputTypeId === 5) {
            const currentValue = formValues[input.id.toString()];

            if (!currentValue) {
                setFormValues({ ...formValues, [input.id.toString()]: "Ne" });
            }

            return <div className="form-check py-2" key={index}>
                <input type="checkbox" className="form-check-input" name={input.id.toString()} placeholder={input.inputLabel} onChange={onInputChange} value={currentValue === "Ano" ? "Ne" : "Ano"} disabled={!!input.isDisabled || step > 3}></input>
                <label dangerouslySetInnerHTML={{ __html: input.inputLabel }}></label><span className="text-danger">{input.isDisabled ? " - Plno" : ""}</span>
            </div>;

        }
        /* Required Checkbox */
        else if (input.registrationInputTypeId === 9) {
            const currentValue = formValues[input.id.toString()];

            if (!currentValue) {
                setFormValues({ ...formValues, [input.id.toString()]: "Ne" });
            }

            return <div className="form-check py-2" key={index}>
                <input type="checkbox" required={true} className="form-check-input" name={input.id.toString()} placeholder={input.inputLabel} onChange={onInputChange} value={currentValue === "Ano" ? "Ne" : "Ano"} disabled={!!input.isDisabled || step > 3}></input>
                <label dangerouslySetInnerHTML={{ __html: input.inputLabel }}></label><span className="text-danger">{input.isDisabled ? " - Plno" : ""}</span>
            </div>;

        }
        /* Only text */
        else if (input.registrationInputTypeId === 10) {
            
            return <div className="p-4" key={index}>
                <label dangerouslySetInnerHTML={{ __html: input.inputLabel }}></label>
            </div>;

        }
        /* Sales referent */
        else if (input.registrationInputTypeId === 4) {

            const currentValue = formValues[input.id.toString()];
            if (!currentValue && currentValue !== "0") {
                setFormValues({ ...formValues, [input.id.toString()]: "0" });
            }

            return <div key={index}>
                <label>{input.inputLabel}</label>
                <select className="form-control mb-2" placeholder={input.inputLabel} name={input.id.toString()} onChange={onInputChange} disabled={step === 4}>
                    {saleReferents.map((referent: string, index: number) => {
                        return <option key={index} value={index}>{referent}</option>;
                    })}
                </select>
            </div>;

        }
        else {
            return <div key={index}>
                <label>{input.inputLabel}</label>
                <input name={input.id.toString()} placeholder={input.inputLabel} onChange={onInputChange} className="form-control mb-2" disabled={step === 4}></input>
            </div>;
        }
    }

    const hasInputsWithPrerequisites = !!(formInputs.find(input => !!input.prerequisiteInputId));

    return <div className="row no-gutters col-12">
        <form onSubmit={formSubmit} className="col-12">
            {/* Remove the registrationId === 146 && registrationType !== 3 after event ends */}
            {isPaid && (registrationId !== 161 || registrationType !== 3) && <p className="text-bold">Fakturační údaje:</p>}
            {registrationId === 159 && <p className="text-bold">Adresa, na kterou vám můžeme zaslat suroviny:</p>}
            {/* :( */}
            {formInputs.map((input: RegistrationInput, index: number) => {
                if ((registrationId == 164 || registrationId == 168) && input.registrationInputTypeId == 5) return;
                return renderInput(input, index, step);
            })}
            {(step === 2) && hasInputsWithPrerequisites && <div>
                <button className="mt-3 btn text-white" style={{ backgroundColor: hexColor }} onClick={() => setStep(3)}>Pokračovat</button>
            </div>}
            {!errorMessage && (step === 3 || (step === 2 && !hasInputsWithPrerequisites) || step === 4) && !isLoading && <><div className="form-check pt-5">
                {/* :( Delete this after the event ends */}
                {(registrationId == 164 || registrationId == 168) && <div>
                    <p>Vaši registraci nelze dokončit bez vašich osobních údajů, které bude společnost <a href="https://investors.delltechnologies.com/node/11831/html" rel="noopener noreferrer" target="_blank">Dell Technologies a její firemní skupina ("Dell")</a> používat v souladu s <a href="https://www.dell.com/learn/cz/cs/czcorp1/policies-privacy" rel="noopener noreferrer" target="_blank">Prohlášením o ochraně osobních údajů</a>, s cílem zlepšit vaši uživatelskou zkušenost a informovat vás o této události a o veškerých aktualizacích nebo změnách týkajících se této události.</p>
                </div>}
                <input type="checkbox" name="GDPRCheck" className="form-check-input" required disabled={step === 4}></input>
                <label dangerouslySetInnerHTML={{ __html: dataProtectionHtml }} className="font-italic"></label>
            </div>
                {/* :( */}
                {(registrationId == 164 || registrationId == 168) && <div>
                    {formInputs.map((input: RegistrationInput, index: number) => {
                        if (input.id && input.registrationInputTypeId === 5) {
                            const currentValue = formValues[input.id.toString()];

                            if (!currentValue) {
                                setFormValues({ ...formValues, [input.id.toString()]: "Ne" });
                            }

                            return <div className="form-check py-2" key={index}>
                                <label className='d-block'>
                                    <a href="https://investors.delltechnologies.com/node/11831/html" target="parent">Společnost Dell Technologies a její skupina firem ("Dell")</a>&nbsp;
                                    by s vámi ráda zůstala v kontaktu, aby vám mohla poskytovat informace o nových produktech, službách, řešeních, exkluzivních nabídkách a speciálních akcích. Podrobnosti o tom, jak nakládáme s informacemi, naleznete v našem&nbsp;
                                    <a href="https://www.dell.com/learn/cz/cs/czcorp1/policies-privacy" target="blank">Prohlášení o ochraně osobních údajů</a>.
                                    Můžete se kdykoli odhlásit.
                                    <br /><br />
                                </label>
                                <input type="checkbox" className="form-check-input" name={input.id.toString()} placeholder={input.inputLabel} onChange={onInputChange} value={currentValue === "Ano" ? "Ne" : "Ano"} disabled={!!input.isDisabled || step > 3}></input>
                                <label dangerouslySetInnerHTML={{ __html: input.inputLabel }}></label><span className="text-danger">{input.isDisabled ? " - Plno" : ""}</span>
                            </div>;
                        }
                    })}
                </div>}

                {/* Remove the registrationId === 146 && registrationType !== 3 after event ends */}
                {priceCZK && (registrationId !== 161 || registrationType !== 3) && <p className="pt-3">Cena: <span className="text-bold">{priceCZK}</span> Kč</p>}
                {/* @ts-ignore */}
                {!isPaid && <button className="mt-3 btn text-white" style={{ backgroundColor: hexColor }} disabled={step === 4}>Dokončit registraci</button>}
                {/* @ts-ignore */}
                {!!isPaid && <button className="mt-3 btn text-white" style={{ backgroundColor: hexColor }} disabled={step === 4}>Závazně dokončit registraci</button>}
            </>
            }
            {errorMessage && <>
                <span className="text-danger d-block py-3">{errorMessage}</span>
                <button className="btn text-white" style={{ backgroundColor: hexColor }} onClick={resetForm}>Vyplnit znovu</button>
            </>
            }
        </form>
    </div>;
}