import { useEffect, useState } from 'react';
import useAdminTokenCheck from 'src/hooks/useAdminTokenCheck';
import { useParams } from 'react-router-dom';

import adminApi from '../../../lib/adminApi';
import AdminRegistrationsDetailResponse from '../../../../../shared/types/serverResponses/AdminRegistrationsDetailResponse';
import { RegistrationUrl } from '../../../../../shared/types/RegistrationUrl';
import { RegistrationUser } from '../../../../../shared/types/RegistrationUser';

import { Link } from 'react-router-dom';

import { RegistrationEditRow, RegistrationNormalRow } from './RegistrationEditRow';

const registrationTypes = {
    1: "Zákazník",
    2: "Obchodní Partner",
    3: "Dell Technologies Tým",
    4: "SWS Tým",
    5: "100 Mega Tým",
    6: "AT Computers",
}

const registrationTypesBahna = {
    "1": "100MEGA Distribution - Zákazník ",
    "2": "100MEGA Distribution - Obchodní partner",
    "3": "100MEGA Distribution - Tým",
    "4": "Dell Technologies - Zákazník",
    "5": "Dell Technologies - Obchodní partner",
    "6": "Dell Technologies - Tým",
}

export default function RegistrationsDetail() {
    const [registrationsDetail, setRegistrationsDetail] = useState<AdminRegistrationsDetailResponse | null>(null);
    /* Ids of users that are currently being edited */
    const [usersBeingEdited, setUsersBeingEdited] = useState<number[]>([]);
    /* Object with data of users currently being edited */
    const [userEditObject, setUserEditObject] = useState<any>({});

    const adminToken = useAdminTokenCheck();

    const { id } = useParams();

    const loadData = async () => {
        const data = await adminApi.getRegistrationsDetail(adminToken, id);
        document.title = data.registrationPage.registrationName;
        setRegistrationsDetail(data);
    }

    useEffect(() => {
        loadData();
    }, []);

    const renderUrlRow = (url: RegistrationUrl, index: number) => {
        return <tr key={index}>
            <td>{url.id} - <Link target="_blank" to={`/r/${url.url}`}>{url.url}</Link></td>
        </tr>
    }

    const getReport = async () => {
        await adminApi.getRegistrationReport(adminToken, id);
    }

    const toggleStopped = async () => {
        if (!registrationsDetail) return;
        await adminApi.setRegistrationIsStopped(adminToken, id, !registrationsDetail?.registrationPage.isStopped);
        setRegistrationsDetail({
            ...registrationsDetail,
            registrationPage: {
                ...registrationsDetail.registrationPage,
                isStopped: !registrationsDetail.registrationPage.isStopped ? 1 : 0
            }
        })
    }

    const deleteUser = async (user: RegistrationUser) => {
        if (!registrationsDetail) return;

        if (!window.confirm(`Opravdu chcete smazat uživatele ${user.firstName} ${user.lastName}?`)) return;

        /* Anti-Ella measurements */
        if (!window.confirm(`Tato operace je NEVRATNÁ, veškerá data o uživateli budou ztracena! Opravdu chcete uživatele smazat?`)) return;

        await adminApi.deleteUser(adminToken, registrationsDetail.registrationPage.id, user.id);

        let newUsers = registrationsDetail?.users.filter((oldUser) => oldUser.id !== user.id);

        setRegistrationsDetail({ ...registrationsDetail, users: newUsers });
    }

    const toggleUserEdit = async (user: RegistrationUser) => {
        if (!registrationsDetail) return;
        if (usersBeingEdited.includes(user.id)) {
            if (window.confirm("Opravdu chcete tyto údaje uložit?")) {
                // api update
                await adminApi.updateRegistrationUser(adminToken, user.id, userEditObject[user.id]);

                /* Change the dataset so the user doesn't have to reload */
                /* Change the basic dataset */
                const newUserArray = registrationsDetail.users.map((oldUser) => {
                    if (oldUser.id !== user.id) {
                        return oldUser;
                    }
                    else {
                        return {
                            ...oldUser,
                            company: userEditObject[user.id]["company"],
                            email: userEditObject[user.id]["email"],
                            lastName: userEditObject[user.id]["lastName"],
                            firstName: userEditObject[user.id]["firstName"],
                        }
                    }
                });

                /* Change the variable-inputs dataset */
                for (const input of userEditObject[user.id].inputs) {
                    setRegistrationsDetail({
                        ...registrationsDetail,
                        users: newUserArray,
                        mappedUserInputs: {
                            ...registrationsDetail.mappedUserInputs,
                            [user.id]: {
                                ...registrationsDetail.mappedUserInputs[user.id],
                                "inputs": {
                                    ...registrationsDetail.mappedUserInputs[user.id]["inputs"],
                                    [input.inputName]: input.inputValue,
                                }
                            }
                        }
                    });
                }

            }
            /* Do not cleanup the object with edited values here if the user decides to come back to it later */
            /* Just remove it from the array */
            const newUsersBeingEdited = usersBeingEdited.filter((oldUser) => oldUser !== user.id);
            setUsersBeingEdited(newUsersBeingEdited);
        }
        else {
            /* Add the user to array of users currently being edited */
            setUsersBeingEdited([...usersBeingEdited, user.id]);
            /* Fill the values for editing */
            const inputsOfUser = registrationsDetail?.inputs.filter((input) => input.registrationTypeId === user.registrationType);
            const inputs = inputsOfUser.map((input) => {
                return {
                    id: input.id,
                    inputName: input.inputName,
                    inputValue: registrationsDetail.mappedUserInputs[user.id]["inputs"][input.inputName]
                }
            });
            setUserEditObject({
                ...userEditObject, [user.id]: {
                    id: user.id,
                    firstName: user.firstName,
                    lastName: user.lastName,
                    company: user.company,
                    email: user.email,
                    inputs
                }
            });
        }
    }

    /* Change of email/name/company */
    const onUserValueChange = (e) => {
        const { value, name } = e.target;
        const { userid } = e.target.dataset;
        const id = parseInt(userid);
        setUserEditObject({
            ...userEditObject,
            [id]: {
                ...userEditObject[id], [name]: value
            }
        });
    }

    /* Change of custom-defined inputs */
    const onUserInputValueChange = (e) => {
        const { value, name } = e.target;
        const { userid } = e.target.dataset;
        const id = parseInt(userid);

        const inputsOfUser = userEditObject[id].inputs.map((input) => {
            if (input.inputName === name) {
                return { ...input, inputValue: value }
            }
            return input;
        });

        setUserEditObject({
            ...userEditObject,
            [id]: {
                ...userEditObject[id],
                inputs: inputsOfUser
            }
        });
    }

    const renderUserRow = (user: RegistrationUser, index: number) => {
        const foundUrl = registrationsDetail?.urls.find((url) => url.id === user.originUrlId);
        const isBeingEdited = usersBeingEdited.includes(user.id);

        /* Return the same row, but make the fields inputs so they can be change */
        if (isBeingEdited) {
            return <RegistrationEditRow
                key={index}
                index={index}
                deleteUser={deleteUser}
                toggleUserEdit={toggleUserEdit}
                user={user}
                registrationTypes={id === "169" ? registrationTypesBahna : registrationTypes}
                foundUrl={foundUrl}
                registrationsDetail={registrationsDetail}
                onUserValueChange={onUserValueChange}
                userEditObject={userEditObject}
                onUserInputValueChange={onUserInputValueChange}
            ></RegistrationEditRow>
        }
        /* If user is not being edited, show just a normal row with data */
        else {
            return <RegistrationNormalRow
                index={index}
                deleteUser={deleteUser}
                toggleUserEdit={toggleUserEdit}
                user={user}
                registrationTypes={id === "169" ? registrationTypesBahna : registrationTypes}
                foundUrl={foundUrl}
                registrationsDetail={registrationsDetail}
                key={index}
            ></RegistrationNormalRow>
        }
    }

    return <div className="row">
        <div className="col-lg-9">
            <h1>{registrationsDetail?.registrationPage.registrationName}</h1>
            <h3>Celkem uživatelů: {registrationsDetail?.users.length}</h3>
            {!!registrationsDetail?.registrationPage.isPaid && <h4>Cena: {registrationsDetail?.registrationPage.priceCZK} Kč - {registrationsDetail?.registrationPage.invoiceItemName}</h4>}
        </div>
        <div className="col-lg-3">
            <h2>Url adresy:</h2>
            <table className="table">
                <thead>
                    <tr>
                        <th>Adresa</th>
                    </tr>
                </thead>
                <tbody>
                    {registrationsDetail?.urls.map((url: RegistrationUrl, index: number) => renderUrlRow(url, index))}
                </tbody>
            </table>
            <button className="btn-success btn" onClick={getReport}>Report</button>
            <button className={`ml-3 btn-${registrationsDetail?.registrationPage.isStopped ? "success" : "danger"} btn`} onClick={toggleStopped}>{registrationsDetail?.registrationPage.isStopped ? "Zapnout" : "Vypnout"} registrace</button>
        </div>
        <h2>Uživatelé:</h2>
        <div style={{ overflowX: "scroll" }} className="col-12">
            <table className="table">
                <thead>
                    <tr>
                        <th>Akce</th>
                        <th>Jméno</th>
                        <th>Příjmení</th>
                        <th>Email</th>
                        <th>Společnost</th>
                        <th>Typ</th>
                        <th>Kdy</th>
                        <th>Odkud</th>
                        <th>Účast</th>
                        <th>Chat / Webinář</th>
                        {!!registrationsDetail?.registrationPage.isPaid && <th>Proforma</th>}
                        {!!registrationsDetail?.registrationPage.isPaid && <th>Daňový doklad</th>}
                        {registrationsDetail?.inputKeys.map((key: string, index: number) => {
                            return <th key={index}>{key}</th>
                        })}
                    </tr>
                </thead>
                <tbody>
                    {registrationsDetail?.users.map((user: RegistrationUser, index: number) => renderUserRow(user, index))}
                </tbody>
            </table>
        </div>

    </div>;
}