import { useEffect, useState } from 'react';
import Api from 'src/lib/api';
import { Webinar } from '../shared/types/Webinar';
import ChatWindow from './ChatWindow';
import { useParams } from 'react-router-dom';

function ChatWrapper() {

    const [webinar, setWebinar] = useState<Webinar | null>(null);
    const [token, setToken] = useState<string | null>();

    const { webinarUrl } = useParams();

    const loadWebinarFromUrl = async () => {
        const { webinar, token } = await Api.getWebinarByUserUrl(webinarUrl);

        document.title = webinar.name;

        setWebinar(webinar);
        setToken(token);
    }

    useEffect(() => {
        loadWebinarFromUrl();
    }, []);

    return <div className="row vh-xl-100 no-gutters">
        <div className="col-lg-12 chat-sizing-100 bg-black">
            {token && webinar?.id && <ChatWindow isChatHidden={false} chatStopped={!!webinar.chatStopped} webinarToken={token} webinarId={webinar.id} hexColor={webinar.hexColor}></ChatWindow>}
        </div>
    </div>;
}

export default ChatWrapper;