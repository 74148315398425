
const normalizeString = (string) => {
    let changedString: string = string.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    changedString = changedString.replaceAll(/ /g, '_');
    changedString = changedString.toLowerCase();

    const charMatch = changedString.match(/[a-z]|_/g);
    if (charMatch) {
        changedString = charMatch?.join("");
    }

    return changedString;
}

export default normalizeString;