import { useState } from "react";
import { io, Socket } from "socket.io-client";

const REACT_APP_BASE_SOCKET_URL: string = process.env.REACT_APP_BASE_SOCKET_URL as string;

const getSocketOptions = (webinarToken) => {
    const options = {
        transportOptions: {
            polling: {
                extraHeaders: {
                    "Authorization": `Bearer ${webinarToken}`
                }
            }
        }
    }
    return options;
}

function useSocket(webinarToken) {
    const [socket, setSocket] = useState<Socket>();

    if (socket === undefined) {
        const socket = io(REACT_APP_BASE_SOCKET_URL, getSocketOptions(webinarToken));
        setSocket(socket);
    }

    return socket
}

export default useSocket;