import useAdminTokenCheck from '../../hooks/useAdminTokenCheck';
import { Redirect, Switch, Route, useRouteMatch } from 'react-router-dom';
import Dashboard from './Dashboard';
import Login from './Login';
import './AdminPanel.css';

function AdminPanel() {
    const adminToken = useAdminTokenCheck();
    let { path } = useRouteMatch();

    return <>
        <Switch>
            <Route path={`${path}/login`}>
                {adminToken ? <Redirect to="/admin"></Redirect> : <Login></Login>}
            </Route>
            <Route path={`${path}`}>
                <Dashboard></Dashboard>
            </Route>
        </Switch>
    </>;
}

export default AdminPanel;