import { useState, useEffect } from 'react';
import useAdminTokenCheck from 'src/hooks/useAdminTokenCheck';
import RegistrationPage from '../../../../../shared/types/RegistrationPage';
import adminApi from '../../../lib/adminApi';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPen, faArchive, faEye } from '@fortawesome/free-solid-svg-icons';

import { Link, useRouteMatch } from 'react-router-dom';
import { format } from 'date-fns';

export default function Registrations() {
    document.title = "Seznam registrací";

    const [registrations, setRegistrations] = useState<RegistrationPage[]>([]);
    const adminToken = useAdminTokenCheck();
    let { path } = useRouteMatch();

    const loadData = async () => {
        const data = await adminApi.getRegistrations(adminToken);
        setRegistrations(data);
    }

    const archiveRegistration = async (id: number) => {
        if (!window.confirm("Opravdu chcete archivovat registraci?")) return;
        await adminApi.archiveRegistration(adminToken, id);
        setRegistrations(registrations.filter((registration) => registration.id !== id));
    }

    useEffect(() => {
        loadData();
    }, []);

    const renderRegistrationRow = (registration: RegistrationPage, index: number) => {
        return <tr key={index}>
            <td>{registration.id}</td>
            <td>{registration.registrationName}</td>
            <td>{registration.webinarStart && format(new Date(registration.webinarStart), "dd. MM. yyyy HH:mm")}</td>
            <td>{registration.webinarName}</td>
            <td>{registration.registeredCount}</td>
            <td>
                <button className="btn btn-danger mr-2" onClick={() => archiveRegistration(registration.id)}>
                    <FontAwesomeIcon icon={faArchive} />
                </button>
                <Link to={`${path}/edit/${registration.id}`}>
                    <button className="btn btn-warning mr-2">
                        <FontAwesomeIcon icon={faPen} inverse />
                    </button>
                </Link>
                <Link to={`${path}/detail/${registration.id}`}>
                    <button className="btn btn-success">
                        <FontAwesomeIcon icon={faEye} />
                    </button>
                </Link>
            </td>
        </tr>;
    }

    return <div className="row">
        <h1 className="col-lg-6">Registrace</h1>
        <div className="col-lg-6 text-right">
            <Link to={`${path}/add`}>
                <button className="btn btn-success">
                    Nová registrační stránka
                </button>
            </Link>
        </div>
        <div style={{ overflowX: "scroll" }} className="col-12">
            <table className="table col-12">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Název</th>
                        <th>Kdy</th>
                        <th>Pro webinář</th>
                        <th>Registrováno</th>
                        <th>Akce</th>
                    </tr>
                </thead>
                <tbody>
                    {registrations.map((registration: RegistrationPage, index: number) => renderRegistrationRow(registration, index))}
                </tbody>
            </table>
        </div>
    </div>;
}