import { useState, useEffect } from 'react';
import useAdminTokenCheck from 'src/hooks/useAdminTokenCheck';
import AdminApi from 'src/lib/adminApi';
import { InputType } from '../../../../../shared/types/InputType';
import { RegistrationType } from '../../../../../shared/types/RegistrationType';
import Webinar from '../../../../../shared/types/Webinar';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPlus, faTrash } from '@fortawesome/free-solid-svg-icons';

import normalizeString from '../../../lib/normalizeString';

import { useHistory } from 'react-router-dom';

import ReactQuill from 'react-quill';
import { REGISTRATION_TYPES } from 'src/shared/enums/registrationTypes';

const EMPTY_ARRAY: any[] = [];

const templates = {
    CRA: {
        registrationName: "Registrace CRA",
        backgroundUrl: "https://webinare.jakub.dev/uploads/996765141cra-SLIDE-POZADI-OR.jpg",
        bannerUrl: "https://webinare.jakub.dev/uploads/cra_vm.png",
        hexColor: "#f59c04",
        emailProviderId: "2",
        webinarId: "",
        urls: [],
        temporaryUrl: "",
        inputType: "1",
        registrationType: [],
        inputLabel: "",
        inputs: EMPTY_ARRAY,
        confirmationEmailHtml: `<p><img src="https://webinare.agenturaxact.eu/img/cra_mail.png" alt="Email Header CRA"></p><p><br></p><p>Dobrý den,</p><p>děkujeme za Vaši registraci na webinář <strong>{{webinarName}}</strong>, který se uskuteční dne <strong>{{webinarStart}}</strong> v <strong>{{webinarStartTime}}</strong>.</p><p>Webinář budete moci shlédnout na odkazu <a href="{{url}}" rel="noopener noreferrer" target="_blank">ZDE</a>.</p><p>Pokud máte jakékoliv dotazy, kontaktujte nás prosím na emailu: cra@agenturaxact.cz.</p><p>S pozdravem,</p><p>Tým CRA</p>`,
        dataProtectionHtml: `Společnost CRA se řídí v oblasti ochrany osobních dat platnými legislativními předpisy EU <br /><a href="https://www.cra.cz/files/2smluvni_podminky_a_ceniky/zasady_zpracovani_osobnich_udaju_pro_zakazniky_cra_platne_k_25_5_2018.pdf" title="Prohlášení o ochraně osobních údajů" target="_BLANK">Prohlášení o ochraně osobních údajů</a><br /> Registrací na tuto akci vyjadřujete souhlas se společností CRA a jejími partnerskými agenturami shromažďovat, uchovávat a používat vaše osobní údaje pro účely služeb poskytovaných v rámci této akce.`,
        capacity_customers: 9999,
        capacity_partners: 9999,
        capacity_dellteam: 9999,
        capacity_swsteam: 0,
        capacity_100mega: 0,
        capacity_atcomputers: 0,
        icalWhere: null,
        icalWho: null,
        hasRegistrationTypesRadio: 1,
        sendIcalWithConfirmation: 1,
    },
    DELL: {
        registrationName: "Registrace DELL",
        backgroundUrl: "https://webinare.jakub.dev/uploads/pozadi-hexa1.jpg",
        bannerUrl: "https://webinare.jakub.dev/uploads/dt-blk.png",
        hexColor: "#3074bc",
        emailProviderId: "1",
        webinarId: "",
        urls: [],
        temporaryUrl: "",
        inputType: "1",
        registrationType: [],
        inputLabel: "",
        inputs: EMPTY_ARRAY,
        confirmationEmailHtml: `<img alt="Email Header Dell" src="https://webinare.agenturaxact.eu/img/mail_header_dell.jpg" style="width:100%;"/><br><br><br>Dobrý den,<br><br>děkujeme za Vaši registraci na webinář <strong>{{webinarName}}</strong>, který se uskuteční dne <strong>{{webinarStart}}</strong> v <strong>{{webinarStartTime}}</strong>.<br>Webinář budete moci shlédnout na odkazu <a href="{{url}}" target="_BLANK">ZDE</a>.<br><br>Pokud máte jakékoliv dotazy, kontaktujte nás prosím na emailu: events@dellnews.cz.<br><br>S pozdravem,<br>Tým Dell Technologies`,
        dataProtectionHtml: `Společnost Dell Technologies se řídí v oblasti ochrany osobních dat platnými legislativními předpisy EU <br /><a href="https://www.dell.com/learn/cz/cs/czcorp1/policies-privacy" title="Prohlášení o ochraně osobních údajů" target="_BLANK">Prohlášení o ochraně osobních údajů</a><br />Registrací na tuto akci vyjadřujete souhlas se společností Dell Technologies a jejími partnerskými agenturami shromažďovat, uchovávat a používat vaše osobní údaje pro účely služeb poskytovaných v rámci této akce.`,
        capacity_customers: 9999,
        capacity_partners: 9999,
        capacity_dellteam: 9999,
        capacity_swsteam: 0,
        capacity_100mega: 0,
        capacity_atcomputers: 0,
        icalWhere: null,
        icalWho: null,
        hasRegistrationTypesRadio: 1,
        sendIcalWithConfirmation: 1,
    },
    SWS: {
        registrationName: "Registrace SWS",
        backgroundUrl: "https://webinare.jakub.dev/uploads/sws-bg.png",
        bannerUrl: "https://webinare.jakub.dev/uploads/sws.png",
        hexColor: "#ffbe44",
        emailProviderId: "3",
        webinarId: "",
        urls: [],
        temporaryUrl: "",
        inputType: "1",
        registrationType: [],
        inputLabel: "",
        inputs: EMPTY_ARRAY,
        confirmationEmailHtml: `Dobrý den,<br><br>děkujeme za Vaši registraci na akci <strong>{{webinarName}}</strong>, která se bude konat dne <strong>{{webinarStart}}</strong> od <strong>{{webinarStartTime}}</strong> hod.<br>Registrace je tímto potvrzena, pár dní před konáním eventu vám zašleme ještě připomínku, ve které budou podrobnější informace ohledně příjezdu atd.<br>Jelikož se jedná o vaření v přírodě, tak i spaní je v tomto duchu, přesněji ve stanech s podlážkou s klasickými postelemi, je ale nutné mít s sebou vlastní spacák.<br>Pokud máte jakékoliv dotazy, kontaktujte nás prosím na emailu: sws@agenturaxact.cz.<br><br>Těšíme se na setkání s vámi,<br>Tým SWS`,
        dataProtectionHtml: `Společnost SWS se řídí v oblasti ochrany osobních dat platnými legislativními předpisy EU <br /><a href="https://www.sws.cz/soubory/Zasady_GDPR.pdf" title="Prohlášení o ochraně osobních údajů" target="_BLANK">Prohlášení o ochraně osobních údajů</a><br />Registrací na tuto akci vyjadřujete souhlas se společností SWS a jejími partnerskými agenturami shromažďovat, uchovávat a používat vaše osobní údaje pro účely služeb poskytovaných v rámci této akce.`,
        capacity_customers: 9999,
        capacity_partners: 9999,
        capacity_dellteam: 9999,
        capacity_swsteam: 9999,
        capacity_100mega: 0,
        capacity_atcomputers: 0,
        icalWhere: null,
        icalWho: null,
        hasRegistrationTypesRadio: 1,
        sendIcalWithConfirmation: 1,
    },
    STOMEGA: {
        registrationName: "Registrace 100 Mega",
        backgroundUrl: "https://webinare.jakub.dev/uploads/100mega_bg2.png",
        bannerUrl: "https://webinare.jakub.dev/uploads/logo-100mega.png",
        hexColor: "#e82300",
        emailProviderId: "4",
        webinarId: "",
        urls: [],
        temporaryUrl: "",
        inputType: "1",
        registrationType: [],
        inputLabel: "",
        inputs: EMPTY_ARRAY,
        confirmationEmailHtml: `Dobrý den,<br><br>děkujeme za Vaši registraci na akci <strong>{{webinarName}}</strong>, která se bude konat dne <strong>{{webinarStart}}</strong> od <strong>{{webinarStartTime}}</strong> hod.<br>Registrace je tímto potvrzena, pár dní před konáním eventu vám zašleme ještě připomínku, ve které budou podrobnější informace ohledně příjezdu atd.<br><br>Pokud máte jakékoliv dotazy, kontaktujte nás prosím na emailu: events@dellnews.cz.<br><br>Těšíme se na setkání s vámi,<br>Tým 100MEGA Distribution`,
        dataProtectionHtml: `Společnost 100MEGA Distribution se řídí v oblasti ochrany osobních dat platnými legislativními předpisy EU <br /><a href="https://b2b.100mega.cz/cs/stranky/72-pravidla-ochrany-osobnich-udaju" title="Prohlášení o ochraně osobních údajů" target="_BLANK">Prohlášení o ochraně osobních údajů</a><br />Registrací na tuto akci vyjadřujete souhlas se společností 100 Mega Distribution a jejími partnerskými agenturami shromažďovat, uchovávat a používat vaše osobní údaje pro účely služeb poskytovaných v rámci této akce.`,
        capacity_customers: 9999,
        capacity_partners: 9999,
        capacity_dellteam: 0,
        capacity_swsteam: 0,
        capacity_100mega: 9999,
        capacity_atcomputers: 0,
        icalWhere: null,
        icalWho: null,
        hasRegistrationTypesRadio: 1,
        sendIcalWithConfirmation: 1,
    }
}


type RegistrationAddFormValues = {
    registrationName: string;
    backgroundUrl: string;
    bannerUrl: string;
    hexColor: string;
    webinarId: string;
    urls: string[];
    temporaryUrl: string;
    emailProviderId: string;

    inputType: string;
    registrationType: string[];
    inputLabel: string;

    inputs: any[];

    confirmationEmailHtml: string;
    dataProtectionHtml: string;

    capacity_customers: number;
    capacity_partners: number;
    capacity_dellteam: number;
    capacity_swsteam: number;
    capacity_100mega: number;
    capacity_atcomputers: number;

    icalWhere: string | null;
    icalWho: string | null;

    hasRegistrationTypesRadio: number;
    sendIcalWithConfirmation: number;
}


const toolbarOptions = [
    ['bold', 'italic', 'underline', 'strike'],
    ['link', 'image'],
    [{ 'list': 'ordered' }, { 'list': 'bullet' }],
    [{ 'header': [1, 2, 3, 4, 5, 6, false] }],
    [{ 'color': [] }, { 'background': [] }],
    [{ 'font': [] }],
    [{ 'align': [] }],
    ['clean'],
];

const modules = {
    toolbar: {
        container: toolbarOptions,
    },
    imageUploader: {
        upload: async file => {
            const formData = new FormData();
            formData.append("file", file);
            const adminToken = localStorage.getItem('admin-token') as string;
            const url = await AdminApi.uploadFile(adminToken, formData);
            return url;
        }
    }
}

const quills = (formValues: RegistrationAddFormValues, modules, confirmationEmailHtmlChange, dataProtectionHtmlChange) => {
    return <>
        <label className="mt-2">Potvrzovací email</label>
        <ReactQuill
            defaultValue={formValues.confirmationEmailHtml}
            modules={modules}
            onChange={(e) => { if (formValues.webinarId) { confirmationEmailHtmlChange(e) } }}
            value={formValues.confirmationEmailHtml}
        />
        <label className="mt-2">Ochrana osobních údajů</label>
        <ReactQuill
            defaultValue={formValues.dataProtectionHtml}
            modules={modules}
            onChange={(e) => { if (formValues.webinarId) { dataProtectionHtmlChange(e) } }}
            value={formValues.dataProtectionHtml}
        />
    </>
}

export default function RegistrationsAdd() {
    document.title = "Přidat registraci";
    const [inputTypes, setInputTypes] = useState<InputType[]>([]);
    const [registrationType, setRegistrationType] = useState<RegistrationType[]>([]);
    const [webinars, setWebinars] = useState<Webinar[]>([]);
    const [selectedBackground, setSelectedBackground] = useState("");
    const [selectedBanner, setSelectedBanner] = useState("");
    const [firstWebinarId, setFirstWebinarId] = useState("");

    const [formValues, setFormValues] = useState<RegistrationAddFormValues>({
        ...templates["DELL"]
    })
    const adminToken = useAdminTokenCheck();

    const history = useHistory();

    const loadData = async () => {
        const inputTypesData = await AdminApi.getInputTypes(adminToken);
        const registrationTypesData = await AdminApi.getRegistrationTypes(adminToken);
        const webinarsData = await AdminApi.getWebinars(adminToken);

        const firstWebinar = webinarsData[0];

        setFirstWebinarId(firstWebinar.id.toString());
        //setFormValues({ ...formValues, webinarId: firstWebinar.id.toString(), registrationName: `Pozvánka na webinář ${firstWebinar.name}` });
        setInputTypes(inputTypesData);
        setRegistrationType(registrationTypesData);
        setWebinars(webinarsData);
    }

    useEffect(() => { loadData() }, []);

    const onChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }

    const onSubmit = (e) => {
        e.preventDefault();
    }

    const addUrl = () => {
        setFormValues({ ...formValues, urls: [...formValues.urls, formValues.temporaryUrl], temporaryUrl: "" })
    }

    const handleMultipleInputChange = (e) => {
        const arr: any[] = []
        for (const option of e.target.selectedOptions) {
            arr.push(option.value)
        }

        setFormValues({ ...formValues, registrationType: arr });
    }

    const addInput = () => {
        const inputsToAdd: any = [];

        for (const type of formValues.registrationType) {
            inputsToAdd.push({
                registrationTypeId: type,
                registrationInputTypeId: formValues.inputType,
                inputName: normalizeString(formValues.inputLabel),
                inputLabel: formValues.inputLabel,
            });
        }

        setFormValues({
            ...formValues,
            inputs: [
                ...formValues.inputs, ...inputsToAdd
            ],
            inputLabel: "",
            registrationType: [],
            inputType: "1"
        });
    }

    const addRegistration = async () => {
        const { urls,
            registrationName,
            inputs,
            webinarId,
            backgroundUrl,
            hexColor,
            bannerUrl,
            emailProviderId,
            confirmationEmailHtml,
            dataProtectionHtml,
            capacity_customers,
            capacity_dellteam,
            capacity_partners,
            capacity_swsteam,
            capacity_100mega,
            capacity_atcomputers,
            icalWhere,
            icalWho,
            hasRegistrationTypesRadio
        } = formValues;

        let url = backgroundUrl;
        if (selectedBackground) {
            const formData = new FormData();
            formData.append("file", selectedBackground);
            url = await AdminApi.uploadFile(adminToken, formData);
        }

        let bannerUrlToAdd = bannerUrl;
        if (selectedBanner) {
            const formData = new FormData();
            formData.append("file", selectedBanner);
            bannerUrlToAdd = await AdminApi.uploadFile(adminToken, formData);
        }

        const isOk = await AdminApi.addRegistration(adminToken, {
            urls,
            registrationName,
            inputs,
            webinarId: webinarId ? webinarId : firstWebinarId,
            backgroundUrl: url,
            hexColor,
            bannerUrl: bannerUrlToAdd,
            emailProviderId,
            confirmationEmailHtml,
            dataProtectionHtml,
            capacity_customers,
            capacity_dellteam,
            capacity_partners,
            capacity_swsteam,
            capacity_100mega,
            capacity_atcomputers,
            icalWhere,
            icalWho,
            hasRegistrationTypesRadio
        });

        if (isOk) {
            history.push(`/admin/registrations`);
        }
    }

    const deleteUrl = (indexToDelete) => {
        const newUrls = formValues.urls.filter((value, index) => index !== indexToDelete);
        setFormValues({ ...formValues, urls: newUrls });
    }

    const deleteInput = (indexToDelete) => {
        const newInputs = formValues.inputs.filter((value, index) => index !== indexToDelete);
        setFormValues({ ...formValues, inputs: newInputs });
    }

    const backgroundFileChange = (e) => {
        setSelectedBackground(e.target.files[0]);
    }

    const bannerFileChange = (e) => {
        setSelectedBanner(e.target.files[0]);
    }

    const confirmationEmailHtmlChange = (confirmationEmailHtml) => {
        setFormValues({ ...formValues, confirmationEmailHtml })
    }

    const dataProtectionHtmlChange = (dataProtectionHtml) => {
        setFormValues({ ...formValues, dataProtectionHtml })
    }

    const setTemplate = (template) => {
        //console.log(templates[template]);
        setFormValues(templates[template]);
    }

    const toggleRegistrationTypesRadio = (e) => {
        const { checked } = e.target;
        setFormValues({ ...formValues, hasRegistrationTypesRadio: checked ? 1 : 0 })
    }

    return <div className="row pb-5">
        <h1>Vytvoření registrace</h1>
        <label className="w-100">Šablona:</label>
        <button className="btn btn-primary" onClick={() => setTemplate("DELL")}>DELL</button>
        <button className="btn btn-warning text-white ml-3" onClick={() => setTemplate("CRA")}>CRA</button>
        <button className="btn btn-success text-white ml-3" onClick={() => setTemplate("SWS")}>SWS</button>
        <button className="btn btn-danger text-white ml-3" onClick={() => setTemplate("STOMEGA")}>100MEGA</button>
        <form className="col-12 pt-5" onSubmit={onSubmit}>
            <div className="row">
                <div className="col-md-6">
                    <h2>Informace o registraci</h2>
                    <label>Název registrace</label>
                    <input type="text" name="registrationName" className="form-control" value={formValues.registrationName} onChange={onChange} placeholder="Název registrace"></input>
                    <label className="mt-2">Pro webinář</label>
                    <select name="webinarId" className="form-control" onChange={onChange}>
                        {webinars.map((webinar: Webinar, index: number) => {
                            return <option key={index} value={webinar.id}>{webinar.id} - {webinar.name}</option>
                        })}
                    </select>
                    <label className="mt-2">Pokud je u kapacity nula, nezobrazí se tato možnost na stránce s registrací. Pokud je registrace bez výběru zákazník/partner/team, celková kapacita je součet všech.</label>
                    <label className="mt-2">Kapacita - kategorie Zákazníci</label>
                    <input type="number" name="capacity_customers" className="form-control" placeholder="Kapacita zákazníci" value={formValues.capacity_customers} onChange={onChange}></input>
                    <label className="mt-2">Kapacita - kategorie Partneři</label>
                    <input type="number" name="capacity_partners" className="form-control" placeholder="Kapacita partneři" value={formValues.capacity_partners} onChange={onChange}></input>
                    <label className="mt-2">Kapacita - kategorie DellTeam</label>
                    <input type="number" name="capacity_dellteam" className="form-control" placeholder="Kapacita dell" value={formValues.capacity_dellteam} onChange={onChange}></input>
                    <label className="mt-2">Kapacita - kategorie SWSTeam</label>
                    <input type="number" name="capacity_swsteam" className="form-control" placeholder="Kapacita sws" value={formValues.capacity_swsteam} onChange={onChange}></input>
                    <label className="mt-2">Kapacita - kategorie 100MEGATeam</label>
                    <input type="number" name="capacity_100mega" className="form-control" placeholder="Kapacita 100mega" value={formValues.capacity_100mega} onChange={onChange}></input>
                    <label className="mt-2">Kapacita - kategorie AT Computers</label>
                    <input type="number" name="capacity_atcomputers" className="form-control" placeholder="Kapacita AT Computers" value={formValues.capacity_atcomputers} onChange={onChange}></input>

                    <div className="form-check my-4">
                        <input type="checkbox" className="form-check-input" onClick={toggleRegistrationTypesRadio} checked={!!formValues.hasRegistrationTypesRadio} />
                        <label className="form-check-label">Zapnout kategorie registrací (zákazníci, partneři, dell...)</label>
                    </div>

                    <h2 className="mt-2">Vstupy pro registraci</h2>
                    <p>Registrace u sebe má pevně jméno, příjmení, společnost a email. Zde vyplňte ostatní vstupy, které budou na stránce s registrací:</p>
                    {formValues.inputs.map((input, index) => {
                        return <p key={index}>
                            <button className="mr-3 btn btn-danger" onClick={() => deleteInput(index)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                            {input.inputLabel} - {input.inputName} - Typ registrace: {REGISTRATION_TYPES[input.registrationTypeId]}
                        </p>
                    })}

                    <div className="row">
                        <div className="col-12">
                            <label className="mt-2">Popisek vstupu na stránce s registrací</label>
                            <input type="text" name="inputLabel" className="form-control" value={formValues.inputLabel} onChange={onChange} placeholder="Popisek vstupu"></input>

                            <label className="mt-2">Typ vstupu</label>
                            <select name="inputType" className="form-control" onChange={onChange} defaultValue="1">
                                {inputTypes.map((type: InputType, index: number) => {
                                    return <option defaultChecked={index === 0} key={index} value={type.id}>{type.inputType}</option>
                                })}
                            </select>

                            <label className="mt-2">Bude pro typ registrace:</label>
                            <select name="registrationType" multiple className="form-control" onChange={handleMultipleInputChange}>
                                {registrationType.map((type: RegistrationType, index: number) => {
                                    return <option key={index} value={type.id}>{type.name}</option>
                                })}
                            </select>
                        </div>
                        <div className="col-12">
                            <button className="btn bg-blue-calm text-white mt-2" onClick={addInput}>
                                Přidat vstup
                            </button>
                        </div>
                    </div>
                    <h2 className="mt-2">Url adresy</h2>
                    <label>Zadané adresy:</label>
                    {formValues.urls.map((url: string, index: number) => {
                        return <div key={index}>
                            <span>{url}</span>
                            <button className="ml-3 btn btn-danger" onClick={() => deleteUrl(index)}>
                                <FontAwesomeIcon icon={faTrash} />
                            </button>
                        </div>
                    })}
                    <div className="row no-gutters">
                        <div className="col-11">
                            <label>Url</label>
                            <input type="text" name="temporaryUrl" className="form-control" placeholder="Url pod kterou bude registrace dostupná" value={formValues.temporaryUrl} onChange={onChange}></input>
                        </div>
                        <div className="col-1 pl-5">
                            <button className="btn btn-success" onClick={addUrl}>
                                <FontAwesomeIcon icon={faPlus} />
                            </button>
                        </div>
                    </div>
                    <button className="btn btn-success col-12 my-5" onClick={addRegistration}>Přidat registraci</button>
                </div>
                <div className="col-md-6">
                    <h2>Nastavení registrace</h2>
                    <label>Pozadí - <a href={formValues.backgroundUrl} target="BLANK">Výchozí</a></label>
                    <input className="form-control mb-3" type="file" name="file" onChange={backgroundFileChange}></input>
                    <label>Logo - <a href={formValues.bannerUrl} target="BLANK">Výchozí</a></label>
                    <input className="form-control mb-3" type="file" name="file" onChange={bannerFileChange}></input>
                    <label>Barva registrace - Výchozí: {formValues.hexColor}</label>
                    <input className="form-control form-control-color mb-3" type="color" id="hexColor" name="hexColor" onChange={onChange} value={formValues.hexColor}></input>
                    <label className="mt-2">Kalendář - pořadatel (kdo) - pokud je prázdné, bude nastaveno dle vybraného emailu</label>
                    <input type="text" name="icalWho" className="form-control" placeholder="Kalendář - kdo" value={formValues.icalWho ? formValues.icalWho : ""} onChange={onChange}></input>
                    <label className="mt-2">Kalendář - kde - pokud je prázdné, bude odkaz na webinář</label>
                    <input type="text" name="icalWhere" className="form-control" placeholder="Kalendář - kde" value={formValues.icalWhere ? formValues.icalWhere : ""} onChange={onChange}></input>
                    <label>Email přijde od:</label>
                    <select className="form-control" name="emailProviderId" id="emailProviderId" onChange={onChange} value={formValues.emailProviderId}>
                        <option className="option" value={1}>
                            Dell Technologies - events@dellnews.cz
                        </option>
                        <option className="option" value={2}>
                            CRA - cra@agenturaxact.cz
                        </option>
                        <option className="option" value={3}>
                            SWS - sws@agenturaxact.cz
                        </option>
                        <option className="option" value={4}>
                            100MEGA - events@dellnews.cz
                        </option>
                        <option className="option" value={5}>
                            DNS - dns@agenturaxact.cz
                        </option>
                    </select>

                    {quills(formValues, modules, confirmationEmailHtmlChange, dataProtectionHtmlChange)}
                </div>
            </div>
        </form>
    </div>;
}