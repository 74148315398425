import { useState, useEffect } from 'react';
import RegistrationPage from '../../shared/types/RegistrationPage';
import { useParams } from 'react-router-dom';
import api from '../../lib/api';

import RegistrationForm from './RegistrationForm';

export default function RegistrationWrapper() {
    const [registrationPage, setRegistrationPage] = useState<RegistrationPage | null>(null);
    const [formValues, setFormValues] = useState({
        registrationType: "0",
        email: "",
        firstName: "",
        lastName: "",
        company: ""
    });
    const [step, setStep] = useState<number>(1);

    const { url } = useParams();

    const getRegistrationPage = async () => {
        try {
            const registration: RegistrationPage = await api.getRegistrationByUrl(url);
            setRegistrationPage(registration);
            document.title = registration.registrationName;
        }
        catch (e) {
            window.location.replace("http://agenturaxact.cz");
        }
    }

    useEffect(() => {
        getRegistrationPage();
    }, []);

    const formSubmit = (e) => {
        e.preventDefault();
        const { email, firstName, lastName, company, registrationType } = formValues;
        if (company && firstName && lastName && email && registrationType) {
            setStep(2);
        }
    }

    const inputChange = (e) => {
        setFormValues({ ...formValues, [e.target.name]: e.target.value });
    }

    const updateForFinalStep = () => {
        setStep(4);
    }

    const resetForm = () => {
        setStep(1);
    }

    return <div className="bg-img min-height-100vh" style={{ backgroundImage: `url(${registrationPage?.backgroundUrl})` }}>
        <div className="container bg-white">
            <div className="row p-1 pm-md-5">
                <div className="col-12">
                    <img src={registrationPage?.bannerUrl} className="float-right partners-logo" />
                </div>
                <h1 className="col-12 text-center pt-3" style={{ color: registrationPage?.hexColor }}>{registrationPage?.registrationName}</h1>
                <div className="px-4 col-12" dangerouslySetInnerHTML={{ __html: registrationPage?.htmlBody ? registrationPage?.htmlBody : "" }}></div>
                {registrationPage?.isFull &&
                    <span className="text-danger d-block w-100 py-3 text-center">Omlouváme se, ale kapacita již byla naplněna. Obraťte se prosím na svého obchodního zástupce.</span>
                }
                {(!!registrationPage?.isStopped || !!registrationPage?.isArchived) && <span className="text-danger d-block w-100 py-3 text-center">Omlouváme se, ale registrace na akci byla již uzavřena.</span>}
                {registrationPage?.id && !registrationPage.isFull && !registrationPage.isStopped && !registrationPage.isArchived && <form className="p-4 col-12" onSubmit={formSubmit}>
                    <div className="row">
                        <div className="col-md-6">
                            <label>Jméno: *</label>
                            <input type="text" name="firstName" className="form-control" required disabled={step !== 1} onChange={inputChange} value={formValues.firstName} placeholder="Jméno" />
                        </div>
                        <div className="col-md-6">
                            <label>Příjmení: *</label>
                            <input type="text" name="lastName" className="form-control" required disabled={step !== 1} onChange={inputChange} value={formValues.lastName} placeholder="Příjmení" />
                        </div>
                        <div className="col-md-6">
                            <label className="mt-3">Společnost: *</label>
                            <input type="text" name="company" className="form-control" required disabled={step !== 1} onChange={inputChange} value={formValues.company} placeholder="Společnost" />
                        </div>
                        <div className="col-md-6">
                            <label className="mt-3">Email: *</label>
                            <input type="email" name="email" className="form-control" required disabled={step !== 1} onChange={inputChange} value={formValues.email} placeholder="vas@email.cz" />
                        </div>
                    </div>
                    {!!registrationPage.hasRegistrationTypesRadio && <div>
                        {registrationPage.id === 152 && <label className="mt-3">Pozvánku jsem obdržel od distributora: *</label>}
                        {registrationPage.id !== 152 && <label className="mt-3">Zvolte jednu z následujících možností: *</label>}
                        {registrationPage.registrationTypes?.map((registrationType) => {
                            const { id, name } = registrationType;

                            /* Jesus christ remove this after the event ends */

                            if (registrationPage.id === 161 && id === 3) {
                                return <div key={id}>
                                    <div className="form-check">
                                        <input type="radio" value={id.toString()} name="registrationType" required disabled={step !== 1} className="form-check-input" onChange={inputChange} checked={formValues.registrationType === id.toString()}></input>
                                        <label className="form-check-label">Tým DNS &amp; Dell Technologies</label>
                                    </div>
                                </div>
                            }
                            else if (registrationPage.id === 162 && id === 3) {
                                return <div key={id}>
                                    <div className="form-check">
                                        <input type="radio" value={id.toString()} name="registrationType" required disabled={step !== 1} className="form-check-input" onChange={inputChange} checked={formValues.registrationType === id.toString()}></input>
                                        <label className="form-check-label">Tým DNS &amp; Dell Technologies</label>
                                    </div>
                                </div>
                            }
                            else if (registrationPage.id === 152 && id === 4) {
                                return <div key={id}>
                                    <div className="form-check">
                                        <input type="radio" value={id.toString()} name="registrationType" required disabled={step !== 1} className="form-check-input" onChange={inputChange} checked={formValues.registrationType === id.toString()}></input>
                                        <label className="form-check-label">SWS</label>
                                    </div>
                                </div>
                            }
                            else if (registrationPage.id === 152 && id === 5) {
                                return <div key={id}>
                                    <div className="form-check">
                                        <input type="radio" value={id.toString()} name="registrationType" required disabled={step !== 1} className="form-check-input" onChange={inputChange} checked={formValues.registrationType === id.toString()}></input>
                                        <label className="form-check-label">100MEGA</label>
                                    </div>
                                </div>
                            }
                            else if (registrationPage.id === 173 && id === 3) {
                                return <div key={id}>
                                    <div className="form-check">
                                        <input type="radio" value={id.toString()} name="registrationType" required disabled={step !== 1} className="form-check-input" onChange={inputChange} checked={formValues.registrationType === id.toString()}></input>
                                        <label className="form-check-label">Tým Dell Technologies a Data Force</label>
                                    </div>
                                </div>
                            }
                            else {
                                return <div key={id}>
                                    <div className="form-check">
                                        <input type="radio" value={id.toString()} name="registrationType" required disabled={step !== 1} className="form-check-input" onChange={inputChange} checked={formValues.registrationType === id.toString()}></input>
                                        <label className="form-check-label">{name}</label>
                                    </div>
                                </div>
                            }

                        })}
                    </div>}
                    {step === 1 && <button className="mt-3 btn text-white" style={{ backgroundColor: registrationPage?.hexColor }}>Pokračovat</button>}
                    {step >= 2 && <button className="mt-3 btn btn-warning text-white" disabled={step > 2} onClick={() => setStep(1)}>Zpět</button>}
                </form>}
                {registrationPage && step > 1 && <RegistrationForm
                    registrationUrl={url}
                    updateStep={updateForFinalStep}
                    setStep={setStep}
                    registrationEmail={formValues.email}
                    registrationType={parseInt(formValues.registrationType)}
                    registrationId={registrationPage.id}
                    firstName={formValues.firstName}
                    lastName={formValues.lastName}
                    company={formValues.company}
                    step={step}
                    resetForm={resetForm}
                    dataProtectionHtml={registrationPage.dataProtectionHtml}
                    hexColor={registrationPage.hexColor}
                    isPaid={!!registrationPage.isPaid}
                    priceCZK={registrationPage.priceCZK ? registrationPage.priceCZK : null}
                ></RegistrationForm>}
                <label className='p-4'>{registrationPage?.id !== 177 ? "* Tato pole jsou vyžadována" : ""}</label>
                {step === 4 && <h4 className="px-3 pt-5 text-center col-12" id="registration-success">Děkujeme za Vaši registraci, potvrzovací e-mail očekávejte v nejbližší době.</h4>}
            </div>
        </div>
    </div>;
}