import { useEffect, useState } from 'react';
import adminApi from '../../../lib/adminApi';
import { EmailTemplate } from '../../../../../shared/types/EmailTemplate';
import useAdminTokenCheck from 'src/hooks/useAdminTokenCheck';

export default function EmailTemplates() {
    const [templates, setTemplates] = useState<EmailTemplate[]>([]);
    const adminToken = useAdminTokenCheck();

    const loadData = async () => {
        const response = await adminApi.getEmailTemplates(adminToken);
        setTemplates(response.templates);
    }

    useEffect(() => {
        loadData();
    }, [])

    return <div>
        <h1>Šablony</h1>
        <table className="table col-12">
            <thead>
                <tr>
                    <th>Id</th>
                    <th>Název</th>
                    <th>Ics</th>
                </tr>
            </thead>
            <tbody>
                {templates.map((template: EmailTemplate, index: number) => {
                    return <tr key={index}>
                        <td>{template.id}</td>
                        <td>{template.name}</td>
                        <td>{template.shouldSendIcal ? "Ano" : "Ne"}</td>
                    </tr>;
                })}
            </tbody>
        </table>
    </div>;
}