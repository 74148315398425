import { useEffect, useState } from 'react';
import useAdminTokenCheck from 'src/hooks/useAdminTokenCheck';

import Webinar from '../../../../../shared/types/Webinar';
import adminApi from '../../../lib/adminApi';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPen, faArchive, faEye } from '@fortawesome/free-solid-svg-icons'

import { Link, useRouteMatch } from 'react-router-dom';

import { format } from 'date-fns';

function Webinars() {
    document.title = "Seznam webinářů";

    const [webinars, setWebinars] = useState<Webinar[]>([]);
    const adminToken = useAdminTokenCheck();
    let { path } = useRouteMatch();

    const loadData = async () => {
        const data = await adminApi.getWebinars(adminToken);
        setWebinars(data);
    }

    const archiveWebinar = async (id: number) => {
        if (!window.confirm("Opravdu chcete archivovat webinář?")) return;
        await adminApi.archiveWebinar(adminToken, id);
        setWebinars(webinars.filter((webinars) => webinars.id !== id));
    }

    useEffect(() => {
        loadData();
    }, [])

    const renderRow = (webinar: Webinar, index: number) => {
        return <tr key={index}>
            <td>{webinar.id}</td>
            <td>{webinar.name}</td>
            <td>{format(new Date(webinar.start), "dd. MM. yyyy HH:mm:ss")}</td>
            <td>
                <button className="btn btn-danger mr-2">
                    <FontAwesomeIcon icon={faArchive} onClick={() => archiveWebinar(webinar.id)} />
                </button>
                <Link to={`${path}/edit/${webinar.id}`}>
                    <button className="btn btn-warning mr-2">
                        <FontAwesomeIcon icon={faPen} inverse />
                    </button>
                </Link>
                <Link to={`${path}/detail/${webinar.id}`}>
                    <button className="btn btn-success">
                        <FontAwesomeIcon icon={faEye} />
                    </button>
                </Link>
            </td>
        </tr>;
    }

    return <div className="row">
        <h1 className="col-lg-6">Všechny webináře:</h1>
        <div className="col-lg-6 text-right">
            <Link to={`${path}/add`}>
                <button className="btn btn-success">
                    Přidat webinář
                </button>
            </Link>
        </div>
        <div style={{ overflowX: "scroll" }} className="col-12">
            <table className="table col-12">
                <thead>
                    <tr>
                        <th>Id</th>
                        <th>Název</th>
                        <th>Start</th>
                        <th>Akce</th>
                    </tr>
                </thead>
                <tbody>
                    {webinars?.map((webinar, index) => renderRow(webinar, index))}
                </tbody>
            </table>
        </div>
    </div>;
}

export default Webinars;