import { useState, useEffect } from 'react';
import RegistrationPage from '../../shared/types/RegistrationPage';
import { useParams } from 'react-router-dom';
import api from '../../lib/api';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCalendar } from '@fortawesome/free-solid-svg-icons';

import { format, addHours } from 'date-fns'
import Webinar from '../../../../shared/types/Webinar';

import { Loading } from '../loading/Loading';

const getGoogleLink = (panel, url) => {
    // because google adds an hour...
    const start = addHours(panel.start, -2);
    const end = addHours(panel.end, -2);
    const description = panel.description.replace(/{{url}}/g, url);
    const title = panel.title.replace(/{{url}}/g, url);
    const where = panel.where.replace(/{{url}}/g, url);
    return `https://www.google.com/calendar/render?action=TEMPLATE&text=${encodeURIComponent(title)}&details=${encodeURIComponent(description)}&location=${encodeURIComponent(where)}&dates=${format(start, "yMMdd")}T${format(start, "HHmmss")}Z%2F${format(end, "yMMdd")}T${format(end, "HHmmss")}Z`;
}

const getOfficeLink = (panel, url) => {
    const description = panel.description.replace(/{{url}}/g, url);
    const title = panel.title.replace(/{{url}}/g, url);
    const where = panel.where.replace(/{{url}}/g, url);
    return `https://outlook.office.com/calendar/0/deeplink/compose?body=${encodeURIComponent(description)}&enddt=${format(panel.end, "yMMdd")}T${format(panel.end, "HH:mm:ss")}&location=${encodeURIComponent(where)}&rru=addevent&startdt=${format(panel.start, "y-MM-dd")}T${format(panel.start, "HH:mm:ss")}&subject=${encodeURIComponent(title)}`;
}

const dayPanels = [
    {
        title: "Úterý 13.4. / Channel Technology Event",
        titleHtml: <span className="text-bold">Úterý 13.4. / Channel Technology Event</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-13T10:00:00"),
        end: new Date("2021-04-13T13:00:00"),
        where: "Channel technology event"
    },
    {
        title: "Středa 14.4. / Channel Technology Event",
        titleHtml: <span className="text-bold">Středa 14.4. / Channel Technology Event</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-14T10:00:00"),
        end: new Date("2021-04-14T13:00:00"),
        where: "Channel technology event"
    },
    {
        title: "Čtvrtek 15.4. / Channel Technology Event",
        titleHtml: <span className="text-bold">Čtvrtek 15.4. / Channel Technology Event</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-15T10:00:00"),
        end: new Date("2021-04-15T13:00:00"),
        where: "Channel technology event"
    },
]

const panels = [
    /* 13. 4. */
    {
        title: "Úvodní slovo channel managera / Pavel Halfar",
        titleHtml: <span><span className="text-bold">Úvodní slovo channel managera</span> / Pavel Halfar</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-13T10:00:00"),
        end: new Date("2021-04-13T10:15:00"),
        where: "Channel technology event"
    },
    {
        title: "Změny v partnerském programu ve FY22 / Petr Bureš",
        titleHtml: <span><span className="text-bold">Změny v partnerském programu ve FY22</span> / Petr Bureš</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-13T10:15:00"),
        end: new Date("2021-04-13T10:35:00"),
        where: "Channel technology event"
    },
    {
        title: "Aktuální přehled prodejních programů a toolů pro partnery / Václav Šindelář",
        titleHtml: <span><span className="text-bold">Aktuální přehled prodejních programů a toolů pro partnery</span> / Václav Šindelář</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-13T10:35:00"),
        end: new Date("2021-04-13T10:55:00"),
        where: "Channel technology event"
    },
    {
        title: "Marketing v době nejisté / Zuzana Ulmanová",
        titleHtml: <span><span className="text-bold">Marketing v době nejisté</span> / Zuzana Ulmanová</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-13T10:55:00"),
        end: new Date("2021-04-13T11:25:00"),
        where: "Channel technology event"
    },
    {
        title: "Dell Financial services / Lubomír Šurina",
        titleHtml: <span><span className="text-bold">Dell Financial services</span> / Lubomír Šurina</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-13T11:30:00"),
        end: new Date("2021-04-13T11:50:00"),
        where: "Channel technology event"
    },
    {
        title: "Jak nabídnout zákazníkům novou formu služby s přidanou hodnotou / Petr Biskup",
        titleHtml: <span><span className="text-bold">Jak nabídnout zákazníkům novou formu služby s přidanou hodnotou</span> / Petr Biskup</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-13T11:50:00"),
        end: new Date("2021-04-13T12:20:00"),
        where: "Channel technology event"
    },
    {
        title: "Diskuzní blok: SMB segment / Michal Seifert, DNS - Tomáš Neuberg, Tech Data - Lukáš Šváb",
        titleHtml: <span><span className="text-bold">Diskuzní blok: SMB segment</span> / Michal Seifert, DNS - Tomáš Neuberg, Tech&nbsp;Data - Lukáš&nbsp;Šváb</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-13T12:20:00"),
        end: new Date("2021-04-13T12:50:00"),
        where: "Channel technology event"
    },
    /* 14. 4. */
    {
        title: "Úvodní slovo generálního manažera / Roman Cabálek",
        titleHtml: <span><span className="text-bold">Úvodní slovo generálního manažera</span> / Roman Cabálek</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-14T10:00:00"),
        end: new Date("2021-04-14T10:15:00"),
        where: "Channel technology event"
    },
    {
        title: "Ochrana dat / Aleš Koreček",
        titleHtml: <span><span className="text-bold">Ochrana dat</span> / Aleš Koreček</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-14T10:15:00"),
        end: new Date("2021-04-14T11:00:00"),
        where: "Channel technology event"
    },
    {
        title: "Taktika a argumenty pro úspěšný prodej HCI/VxRail / David Kolenatý",
        titleHtml: <span><span className="text-bold">Taktika a argumenty pro úspěšný prodej HCI/VxRail</span> / David Kolenatý</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-14T11:05:00"),
        end: new Date("2021-04-14T11:50:00"),
        where: "Channel technology event"
    },
    {
        title: "Nové servery 15. generace / Jan Haken, Pavel Veselý",
        titleHtml: <span><span className="text-bold">Nové servery 15. generace</span> / Jan Haken, Pavel Veselý</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-14T11:50:00"),
        end: new Date("2021-04-14T12:50:00"),
        where: "Channel technology event"
    },
    /* 15. 4. */
    {
        title: "Výhody uložišť pro nestrukturovaná data / Martin Lenk",
        titleHtml: <span><span className="text-bold">Výhody uložišť pro nestrukturovaná data</span> / Martin Lenk</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-15T10:00:00"),
        end: new Date("2021-04-15T10:45:00"),
        where: "Channel technology event"
    },
    {
        title: "Novinky v klientském portfoliu / Petr Zajíček",
        titleHtml: <span><span className="text-bold">Novinky v klientském portfoliu</span> / Petr Zajíček</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-15T10:45:00"),
        end: new Date("2021-04-15T11:30:00"),
        where: "Channel technology event"
    },
    {
        title: "Příležitosti s VMware / Igor Stonawski",
        titleHtml: <span><span className="text-bold">Příležitosti s VMware</span> / Igor Stonawski</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-15T11:35:00"),
        end: new Date("2021-04-15T12:05:00"),
        where: "Channel technology event"
    },
    {
        title: "Průřez Midrange portfoliem / Jakub Matoušek",
        titleHtml: <span><span className="text-bold">Průřez Midrange portfoliem</span> / Jakub Matoušek</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-15T12:05:00"),
        end: new Date("2021-04-15T12:50:00"),
        where: "Channel technology event"
    },

    /*{
        title: "Poděkování, rozloučení – případně pozvání na další program / Zuzana Ulmanová",
        titleHtml: <span><span className="text-bold">Poděkování, rozloučení – případně pozvání na další program</span> / Zuzana Ulmanová</span>,
        description: "Pro připojení klikněte zde: {{url}}",
        start: new Date("2021-04-15T12:50:00"),
        end: new Date("2021-04-15T12:55:00"),
        where: "Channel technology event"
    },*/
];

const lastPanel = {
    title: "Live online koncert kapely WOHNOUT",
    titleHtml: <span className="text-bold">Live online koncert kapely WOHNOUT</span>,
    description: "Pro připojení klikněte zde: {{url}}",
    start: new Date("2021-04-15T19:00:00"),
    end: new Date("2021-04-15T20:00:00"),
    where: "Channel technology event"
}

const generateWebinarIcal = (webinarName: string, webinarStart: Date, webinarEnd: Date, webinarUrl: string, description: string) => {
    // Custom generation of iCal event, the library ical-generator will be removed
    const now = new Date();
    return `BEGIN:VCALENDAR
PRODID:-//Microsoft Corporation//Outlook 16.0 MIMEDIR//EN
VERSION:2.0
METHOD:PUBLISH
X-MS-OLK-FORCEINSPECTOROPEN:TRUE
NAME:Pozvánka na webinář
X-WR-CALNAME:Pozvánka na webinář
BEGIN:VEVENT
CLASS:PUBLIC
TIMEZONE-ID:Europe/Prague
X-WR-TIMEZONE:Europe/Prague
CREATED:${format(now, "yMMdd'T'HHmmss")}
DESCRIPTION:${description}
DTEND:${format(webinarEnd, "yMMdd'T'HHmmss")}
DTSTAMP:${format(now, "yMMdd'T'HHmmss")}
DTSTART:${format(webinarStart, "yMMdd'T'HHmmss")}
LAST-MODIFIED:${format(now, "yMMdd'T'HHmmss")}
LOCATION:${webinarUrl}
PRIORITY:1
SEQUENCE:0
SUMMARY;LANGUAGE=cs:${webinarName}
TRANSP:OPAQUE
UID: ${Date.now()}
X-MICROSOFT-CDO-BUSYSTATUS:BUSY
X-MICROSOFT-CDO-IMPORTANCE:2
X-MS-OLK-AUTOFILLLOCATION:FALSE
ORGANIZER;CN="Dell Technologies":mailto:events@delltechnologies.com
END:VEVENT
END:VCALENDAR`;
}

export default function PanelWrapper() {
    const { userUrl } = useParams();
    const [webinarData, setWebinarData] = useState<Webinar>();

    const loadData = async () => {
        const { webinar, token } = await api.getWebinarByUserUrl(userUrl);
        setWebinarData(webinar);
    }

    useEffect(() => {
        loadData();
    }, []);

    const webinarUrl = `${process.env.REACT_APP_BASE_FE_URL}/w/${userUrl}`;

    const downloadIcal = (panel) => {

        const description = panel.description.replace(/{{url}}/g, webinarUrl);
        const title = panel.title.replace(/{{url}}/g, webinarUrl);


        const ical = generateWebinarIcal(title, panel.start, panel.end, webinarUrl, description)

        const download = document.createElement('a');
        download.setAttribute('href', 'data:text/calendar;charset=utf-8,' + encodeURIComponent(ical));
        download.setAttribute('download', "invitation.ics");

        download.style.display = 'none';
        document.body.appendChild(download);

        download.click();

        document.body.removeChild(download);
    }

    if (!webinarData) return <Loading />;

    const renderPanel = (panel, index) => {
        return <div className="col-12 px-0 row no-gutters padding-panels" key={index}>
            <div className="col-lg-9">
                <h1 className="pl-0 text-white webinar-panel-title"><span className="pr-3">{format(panel.start, "HH:mm")} - {format(panel.end, "HH:mm")}</span>{panel.titleHtml}</h1>
            </div>
            <div className="col-lg-3 text-white">
                {/*
                    <a target="_BLANK" className="btn bg-blue-calm text-white ml-3" href={getOfficeLink(panel, webinarUrl)}>Office.com</a>
                 */}
                <a target="_BLANK" className="ml-2 text-underline text-white" href={getGoogleLink(panel, webinarUrl)}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <span className="ml-2">Google</span>
                </a>
                <span className="hover-pointer ml-3 text-underline" onClick={() => downloadIcal(panel)}>
                    <FontAwesomeIcon icon={faCalendar} />
                    <span className="ml-2">Outlook</span>
                </span>
            </div>
            {/*<p className="pl-1">{format(panel.start, "dd. MM. HH:mm")} - {format(panel.end, "HH:mm")}</p>*/}
        </div>;
    }

    return <div className="bg-img min-height-100vh" style={{ backgroundImage: `url(https://webinare.jakub.dev/uploads/CT-AGENDA+pozadi.jpg)` }}>
        <div className="row no-gutters">
            <div className="col-lg-3 d-lg-block d-none">
                <img className="w-100" src="https://webinare.jakub.dev/uploads/channel/logavlevo.png" alt="Loga partneři" />
            </div>
            <div className="col-lg-3 d-lg-none d-block">
                <img className="w-100" src="https://webinare.jakub.dev/uploads/channel/logavlevosmall.png" alt="Loga partneři" />
            </div>
            <div className="col-lg-9 no-gutters">
                <div className="row no-gutters">
                    <div className="col-12 text-right">
                        <img className="partners-logo pt-5 pb-3 px-5" src="https://webinare.jakub.dev/uploads/channel/agenda_loga_pravo_nahore.png" alt="Loga partneři" />
                    </div>
                    <div className="col-lg-12">
                        <div className="pl-4 py-3 opaque-bg-agenda">
                            <div className="row no-gutters">
                                <div className="col-10">
                                    <h1 className="text-light text-white pb-2">AGENDA:</h1>
                                    <p className="text-white agenda-font text-bold py-3">Děkujeme za vaši registraci a těšíme se na společné setkání na konferenci Channel&nbsp;Technology&nbsp;Event. Zde naleznete celou třídenní agendu, ve které si můžete přidat do kalendáře buď celé dny nebo také jednotlivé pracovní bloky. </p>
                                </div>
                                <div className="col-lg-9">
                                    <h2 className="text-white text-bold pb-4 pt-4">
                                        13.4. od 10:00 do 13:00
                                    </h2>
                                </div>
                                <div className="col-lg-3 text-white pb-3 pt-4 mt-3">
                                    <a target="_BLANK" className="ml-2 text-underline text-white" href={getGoogleLink(dayPanels[0], webinarUrl)}>
                                        <FontAwesomeIcon icon={faCalendar} />
                                        <span className="ml-2">Google</span>
                                    </a>
                                    <span className="hover-pointer ml-3 text-underline" onClick={() => downloadIcal(dayPanels[0])}>
                                        <FontAwesomeIcon icon={faCalendar} />
                                        <span className="ml-2">Outlook</span>
                                    </span>
                                </div>
                            </div>
                            {panels.slice(0, 7).map((panel, index) => renderPanel(panel, index))}
                            <div className="row no-gutters">
                                <div className="col-lg-9">
                                    <h2 className="text-white text-bold py-4">
                                        14.4. od 10:00 do 13:00
                                    </h2>
                                </div>
                                <div className="col-lg-3 text-white pb-3 pt-4 mt-3">
                                    <a target="_BLANK" className="ml-2 text-underline text-white" href={getGoogleLink(dayPanels[1], webinarUrl)}>
                                        <FontAwesomeIcon icon={faCalendar} />
                                        <span className="ml-2">Google</span>
                                    </a>
                                    <span className="hover-pointer ml-3 text-underline" onClick={() => downloadIcal(dayPanels[1])}>
                                        <FontAwesomeIcon icon={faCalendar} />
                                        <span className="ml-2">Outlook</span>
                                    </span>
                                </div>
                            </div>
                            {panels.slice(7, 11).map((panel, index) => renderPanel(panel, index))}
                            <div className="row no-gutters">
                                <div className="col-lg-9">
                                    <h2 className="text-white text-bold py-4">
                                        15.4. od 10:00 do 13:00
                                    </h2>
                                </div>
                                <div className="col-lg-3 text-white pb-3 pt-4 mt-3">
                                    <a target="_BLANK" className="ml-2 text-underline text-white" href={getGoogleLink(dayPanels[2], webinarUrl)}>
                                        <FontAwesomeIcon icon={faCalendar} />
                                        <span className="ml-2">Google</span>
                                    </a>
                                    <span className="hover-pointer ml-3 text-underline" onClick={() => downloadIcal(dayPanels[2])}>
                                        <FontAwesomeIcon icon={faCalendar} />
                                        <span className="ml-2">Outlook</span>
                                    </span>
                                </div>
                            </div>
                            {panels.slice(11).map((panel, index) => renderPanel(panel, index))}
                            <div className="pt-3"></div>
                            {renderPanel(lastPanel, 999)}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </div >;
}